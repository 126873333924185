import { useLayoutEffect, useEffect } from "react";
import { toast } from 'react-toastify'

import BirthYearInput from "../_clientComponents/BirthYearInput/BirthYearInput";
import BPNumbers from "../_clientComponents/BPNumbers/BPNumbers";
import HeightInput from "../_clientComponents/HeightInput/HeightInput";
import LayoutScreen from "../components/Layout";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import AdbuList from "../components/AdbuList";
import Summary from "./Summary/Summary";
import AdbuYesConfirm from "../_clientComponents/Modals/AdbuYesConfirm"
import AdbuNoConfirm from "../_clientComponents/Modals/AdbuNoConfirm"
import BirthControlConfirm from "../_clientComponents/Modals/BirthControlConfirm"
import WeightInput from "../_clientComponents/WeightInput/WeightInput";
import WarningBlock from "../_clientComponents/Warning/Warning";
import Outcome from './Outcome'
import { ClinicianCompletionToast } from '../cliniciantoast'

import images from "../img/index";

import Codescripts from "@galen/codescripts";

export function LayoutComponent({ state, spec, onBack, showBackButton, children }) {
  // if there is an outcome or kickout, the current 
  // index is the length of the children since the 
  // assessment is considered complete
  const calculatedCurrent = state.isCompleted || state.isOutcome || state.isKickout ? spec.children.length : state.index + 1;
  const showProgress = spec && spec.global && spec.global.progress;

  const showToastOn = {
    "DocPharmAttestationNoScreen": 1,
    "DocPharmAttestationNoBPNumbersScreen": 1,
    "bloodPressureStart": 1,
  }

  const completedAssessment = state.isKickout || state.isPostCompleted
  const postId = spec.post?.[state.postIndex].id

  const displayToast = state.recordType == "clinician"
    && (completedAssessment || showToastOn[postId])

  useEffect(() => {
    if (displayToast) {
      toast(<ClinicianCompletionToast completedAssessment={completedAssessment}/>, {autoClose: false, closeOnClick: false, draggable: false})
    } else {
      toast.dismiss()
    }
  }, [displayToast, completedAssessment])

  return (
    <LayoutScreen onBack={onBack} showBackButton={showBackButton} state={state}>
      {
        showProgress &&
        <ProgressBar current={calculatedCurrent} total={spec.children.length} />
      }
      {children}
    </LayoutScreen>
  );
}

export default function AssessmentPage() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return <Codescripts
    images={images}
    Components={{
      Layout: LayoutComponent,
      Summary: Summary,
      Outcome,
      AssessmentComponents: {
        AdbuList,
        AdbuYesConfirm,
        AdbuNoConfirm,
        BirthControlConfirm,
        BirthYearInput,
        BPNumbers,
        HeightInput,
        WarningBlock,
        WeightInput,
      },
    }}
  />;
}

import React from "react";
// import { useSelector } from "react-redux"

import { Auth } from '../Auth'
// import { googleAuth, appleAuth } from "../../components/Auth"

// Assessment component for signing in during assessment
function SignInComponent({ onSubmit, onEvent, global, confirmGuest }) {
  // const authed = useSelector((state) => state.auth.authed);

  // get the authSupport object from the global object
  // this keeps track of which auth methods are supported
  const { authSupport } = global;

  const handleOnSubmit = () => {
    // do other auth-logic here eventually maybe but 
    // we need onSubmit to progress the assessment
    onSubmit();
  }

  return <>
    <Auth onSubmit={handleOnSubmit} authSupport={authSupport} confirmGuest={confirmGuest} />

    <p className='terms'>
      By using your Google or Apple ID you accept our <a href="#">terms of use</a> and <a href="#">privacy policy</a>.
    </p>

    <p className='progress'>
      If you continue as a guest, your answers will not be saved when
      you quit and you will need to restart.
    </p>
  </>;
}

export default SignInComponent;

import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams, Link } from "react-router-dom";

import { assessmentStarted, reserveAssessmentNumber } from "../reducers";
import router from '../history'
import { createPromise } from '../subscribers'
import { authClient, client } from "@galen/api-client";

import landingDesktop from "../img/landing.png"
import landingMobile from "../img/landing-mobile.png"
import lock from "../icn/lock.svg"
import logo from "../img/logo.png"

import "./LandingPage.css";

export default function LandingPage() {
  const appVersion = process.env.REACT_APP_VERSION || "1.2.34";
  const dispatch = useDispatch();
  const assessment = useSelector(state => state.assessment)
  const [searchParams] = useSearchParams()
  const clinician = searchParams.get("clinician")
  const invite = searchParams.get("invite")
  const loadingAssessmentNumber = useRef(createPromise())

  useEffect(() => {
    if (!clinician) {
      dispatch(reserveAssessmentNumber())
        .then(() => loadingAssessmentNumber.current.resolve())
    }
    sessionStorage.removeItem('checkoutComplete')
    sessionStorage.removeItem('customerEmail')
    sessionStorage.removeItem('sessionId')
    sessionStorage.removeItem('token')
  }, []);

  const start = async () => {
    if (clinician) {
      const { assessmentId, authToken } = await client.takeClinicianAssessment({
        token: clinician,
      })
      await authClient.setToken({ token: authToken })
      router.push(`/assessment/${assessmentId}`)
      return
    }

    if (!invite) { return }

    await loadingAssessmentNumber.current.promise
    await authClient.guestLogin();
    dispatch(assessmentStarted(invite));
  };

  return (
    <div className="landing-page">
      <header className="landing-header">
        <img className="header-logo" src={logo} alt="logo" />

        <div className="flex gap-4 items-center">
          <div className="hidden md:block">Already have an account?</div>
          <Link className="header-btn" to="/sign-in">Sign In</Link>
        </div>
      </header>
      <main className="landing-main">
        <div className="md:hidden">
          <img className="object-cover" src={landingMobile} alt="" />
        </div>

        <div className="img-container">
          <img className="" src={landingDesktop} alt="" />
          <span className="version">App Version { appVersion }</span>
        </div>

        <div className="content-container">
          <div className="content-area">
            <h1>Welcome to Zena</h1>

            <p className="mb-4">Find out if Zena is safe for you!</p>

            <p>Take this health survey to find out!</p>
            <p>This will only take a few minutes.</p>
            <p>Let's get started!</p>

            <p className="my-4 md:my-8 text-xs italic">For investigational use only</p>

            <div className="cta">
              <button onClick={start} className="cta-btn">Get Started</button>
              <div className="mb-4 flex gap-2 justify-center items-center text-sm">
                <img src={lock} alt="" />
                Private and secure
              </div>

              <div className="mb-4 md:mb-0">
                Already have an account? <Link to="/sign-in">Sign In</Link>
              </div>

              <div className="assessment-id self-start">
                Assessment ID <span>{
                  invite
                    ? assessment.assessmentNumber
                    : "N/A"
                }</span>
              </div>

              <div className="md:hidden text-sm">
                App Version { appVersion }
              </div>
            </div>
          </div>
        </div>

      </main>
    </div>
  );
}

export function ClinicianCompletionToast({completedAssessment}) {
  return (
    <div style={{fontSize: "16px", lineHeight: "initial"}}>
    {
      completedAssessment && "You have successfully completed this health survey."
    }
      <a href="#" style={{display: "block", marginTop: "8px"}} onClick={e => {
        e.preventDefault()
        const s = window.open("", "_self")
        s.close()
      }}>Click here to return to the Study Administration Portal</a>
    </div>
  )
}

import React from 'react';
import warning from '../../icn/warning.svg';

import './Warning.css';

export function join(arr) {
  if (arr.length === 0) {
    return ''
  }

  if (arr.length === 1) {
    return arr[0]
  }
  return arr.slice(0, arr.length - 1).join(', ') + ` and ${arr[arr.length - 1]}`
}

export function getMedications(answers) {
  let medications = [
    ...(answers.hepatitisMeds || []),
    ...(answers.thyroidMeds || []),
    ...(answers.epilepsyMeds || []),
    ...(answers.hivMeds || []),
    ...(answers.cholesterolMeds || []),
    ...(answers.antibacterialMeds || []),
    ...(answers.otherMeds || []),
  ];
  return medications;
}

export function formatMedications(medications) {
  const formatted = [];

  medications.forEach((m) => {
    m = m.replaceAll("_", " ");

    // https://ideaevolver.atlassian.net/browse/CADENCE-503
    if (m === "st johns wort") {
      m = "St John's Wort";
    }

    formatted.push(m);
  });

  return formatted;
}

function WarningBlock({ state }) {
  const medications = formatMedications(getMedications(state.answers))
  let text = 'Use back up birth control while using: ';
  text += join(medications);
  return <div className="warning-block">
    <img src={warning} alt="Warning" />
    <div className="warning-block__text">
      Use back up birth control while using: {join(medications)}
    </div>
  </div>
}

export default WarningBlock;
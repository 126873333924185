import React, { useState } from "react";
import Checkbox from "./Checkbox";

import './BirthControlConfirm.css';

function BirthControlConfirm({ id, onClose, onEvent, onConfirm, children = []}) {
  const [checked, setChecked] = useState(false);
  const handleOnClick = () => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: "yes" });
    onConfirm({ [id]: "yes" });
    onClose();
  };

  return (
    <div className="bc-confirm-modal" id={id}>
      <h2>Risk of using more than one hormonal birth control</h2>

      <p>
        You must stop using your current birth control product when you start
        using Zena. Two forms of birth control should not be used together as
        this can increase the chance of serious health issues such as heart
        attack, stroke and even death. (Condoms are ok to continue)
      </p>

      <Checkbox checked={checked} value="confirm" onCheck={setChecked}>
        I have read and acknowledged
      </Checkbox>

      <button
        className="button button-primary"
        disabled={!checked}
        onClick={handleOnClick}
      >
        Confirm
      </button>
    </div>
  );
};

export default BirthControlConfirm;

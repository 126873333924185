import React from 'react'
import Markdown from 'react-markdown'

import Checkbox from './Checkbox.jsx'

function CheckboxGroup({ 
  id,
  question,
  instructions = "Select all that apply.",
  options, 
  onEvent, 
  value
}) {
  // Separate "none" and "all" options from regular options
  const regularOptions = options.filter(option => option.value !== "none" && option.value !== "all");
  const noneOption = options.find(option => option.value === "none");
  const allOption = options.find(option => option.value === "all");

  const isNoneChecked = value !== undefined && value.length === 0;
  const isAllChecked = value !== undefined && value.length === regularOptions.length;

  const onCheckRegular = (opt, checked) => {
    let newValue;
    if (checked) {
      const currentValues = value === undefined ? [] : value;
      newValue = [...currentValues, opt.value];
    } else {
      newValue = value.filter((v) => v !== opt.value);
      if (newValue.length === 0) {
        newValue = undefined;
      }
    }
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue });
  };

  const onCheckNone = (checked) => {
    let newValue;
    if (checked) {
      newValue = [];
    } else {
      newValue = undefined;
    }
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue });
  };

  const onCheckAll = (checked) => {
    let newValue;
    if (checked) {
      newValue = regularOptions.map((opt) => opt.value);
    } else {
      newValue = undefined;
    }
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue });
  };

  const children = regularOptions.map((opt, i) => (
    <Checkbox
      {...opt}
      name={id}
      key={`checkbox-${id}-${opt.value}-${i}`}
      onCheck={(checked) => onCheckRegular(opt, checked)}
      checked={value !== undefined && value.includes(opt.value)}
    />
  ));

  if (noneOption) {
    children.push(
      <Checkbox
        {...noneOption}
        name={id}
        key={`checkbox-none-${id}-${regularOptions.length}`}
        onCheck={onCheckNone}
        checked={isNoneChecked}
      />
    );
  }

  if (allOption) {
    children.push(
      <Checkbox
        {...allOption}
        name={id}
        key={`checkbox-all-${id}-${regularOptions.length + 1}`}
        onCheck={onCheckAll}
        checked={isAllChecked}
      />
    );
  }

  return <>
    <div className='assessment-checkbox-question-group'>
      { question && <p className='assessment-checkbox-question-group__question'>{question}</p> }
      <div className='assessment-checkbox-question-group__instructions'>
        <Markdown children={instructions} />
      </div>
    </div>
    <div className="assessment-checkbox-group__group">
      {children}
    </div>
  </>;
}

export default CheckboxGroup

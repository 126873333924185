import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import rootReducer, { appleAuthSuccess, googleAuthSuccess } from './reducers'

export const loadStateFromSessionStorage = () => {
  const authed = sessionStorage.getItem("authed");
  const token = sessionStorage.getItem("token");
  const state = { auth: {} };

  if (authed) {
    state.auth.authed = true;
  }

  if (token) {
    state.auth.token = JSON.parse(token);
  }

  return state;
};

export const configureStore = (initialState) => {
  return createStore(
    rootReducer, 
    initialState, 
    applyMiddleware(thunk)
  );
};

export const setUpEventListeners = (store) => {
  document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    store.dispatch(appleAuthSuccess(event.detail.authorization));
  });

  document.addEventListener('AppleIDSignInOnFailure', console.error);


  window.addEventListener("message", (event) => {
    if (event.origin !== window.location.origin) return;

    if (event.data.type === "googleConsentSuccess") {
      store.dispatch(googleAuthSuccess(event.data.code));
    }
  }, false);
};

const preloadedState = loadStateFromSessionStorage();
const store = configureStore(preloadedState);
store.subscribe(() => {
  // for debug
  // console.log(store.getState())
})

setUpEventListeners(store);

// window.store = store // for debugging

export default store

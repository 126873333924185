import React, { useEffect } from "react";

import BaseScreen from "../BaseScreen/BaseScreen";

function Screen({
  id,
  onEvent,
  onSubmit,
  showContinueButton,
  title,
  children,
  heroImage,
  buttonText,
  backButtonText,
  confirm,
  answers,
  getValue,
  screenSpec,
  onBack,
  state,
}) {
  const handleOnSubmit = () => onSubmit()

  useEffect(() => {
    onEvent({ componentId: id, type: "SCREEN_DISPLAYED" });
  }, [id]);

  return (
    <BaseScreen
      id={id}
      answers={answers}
      contentType={screenSpec.contentType}
      title={title}
      heroImage={heroImage}
      showButton={showContinueButton == true}
      disableContinue={null}
      buttonText={ buttonText }
      showBackButton={screenSpec.showBackButton}
      backButtonText={backButtonText}
      onBack={onBack}
      state={state}
      screenSpec={screenSpec}
      onSubmit={handleOnSubmit}
      confirm={confirm}
    >
      {children}
    </BaseScreen>
  );
}

export default Screen;

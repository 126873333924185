import React from "react";
import Markdown from "react-markdown";

import "./Radio.css";

function Radio({
  name,
  value,
  labelText,
  disabled = false,
  checked = false,
  ariaLabel = labelText,
  onChange,
}) {
  return (
    <label
      className="assessment-radio"
      htmlFor={`assessment-radio-${value}`}
      data-disabled={disabled}
    >
      <div className="relative flex">
        <input
          className="assessment-radio__input"
          id={`assessment-radio-${value}`}
          name={name}
          value={value}
          disabled={disabled}
          checked={checked}
          type="radio"
          onChange={(e) => onChange(e.target.value)}
          aria-label={ariaLabel}
        />

        <span className="assessment-radio__custom-radio"></span>
      </div>
      <div className="assessment-radio__label">
        {typeof labelText === "string" ? (
          <Markdown>{labelText}</Markdown>
        ) : typeof labelText === "object" ? (
          labelText.map((text, index) => (
            <Markdown key={index}>{text}</Markdown>
          ))
        ) : null}
      </div>
    </label>
  );
}

export default Radio;

import React, { Children } from "react";

import AssessmentTitle from "../AssessmentComponents/Title";
import Button from "../AssessmentComponents/Button";

import { questions } from "../../spec/utils";

import "./BaseScreen.css";

import { useAssessment } from "../../context/assessmentContext";

/**
 * BaseScreen component that conditionally renders the appropriate semantic markup based on the contentType.
 * It can either render a 'form' or 'info' type screen.
 *
 * @component
 * @param {string} id - Question identifier
 * @param {('form'|'info')} props.contentType - Type of the content to be rendered (defaults to 'form').
 * @param {string} props.title - The title of the screen. (h1)
 * @param {React.ReactNode} props.children - Child components to be rendered within the screen.
 * @param {boolean} props.showButton - Whether to show the 'Next' button.
 * @param {string} props.backButtonText - Text for the 'Back' button.
 * @param {boolean} props.showBackButton - Whether to show the 'Back' button.
 * @param {string} props.buttonText - Text for the 'Next' button.
 * @param {boolean} props.disableContinue - Whether to disable the 'Next' button.
 * @param {Function} props.onSubmit - Handler for the form submit event.
 * @param {Function} props.onBack - Handler for the 'Back' button click event.
 */
function BaseScreen({
  id,
  answers,
  contentType,
  title,
  children,
  confirm,
  screenSpec = {},
  showButton = true,
  buttonText = "Next",
  showBackButton = false,
  backButtonText = "Back",
  disableContinue,
  assessmentNumber = null,
  onSubmit,
  onBack,
  images,
}) {
  const { spec, state } = useAssessment();
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  // This is responsible for calculating the remaining questions that need to be answered
  // before the user can continue to the next screen.
  const remainingQuestions = questions(screenSpec).filter(
    (q) =>
      answers[q.id] === undefined ||
      answers[q.id] === null ||
      answers[q.id] === ""
  );

  /**
   * Function to build form content for the BaseScreen component. This includes all
   * content such as title, subtitle, and buttons, nested within a form tag.
   *
   * @returns {React.ReactNode} JSX content for the form variant of BaseScreen.
   */
  const buildFormContent = () => (
    <>
      <form
        className={`assessment-base form ${id}`}
        onSubmit={handleSubmit}
        data-testid="form-element"
      >
        <fieldset className="fieldset">
          {title && <legend>{title}</legend>}
          {Children.toArray(children).map((child) => child)}
        </fieldset>

        {(showBackButton || showButton) && (
          <div
            className={`assessment-base__button-group ${!spec?.global?.navBackButton && showBackButton ? "two-btn" : ""}`}
          >
            {!spec.global.navBackButton && showBackButton && (
              <Button onClick={onBack} className="col-span-1">
                {backButtonText}
              </Button>
            )}
            {showButton && (
              <Button
                type="submit"
                disabled={remainingQuestions.length > 0 || disableContinue}
              >
                {buttonText}
              </Button>
            )}
          </div>
        )}
      </form>
    </>
  );

  /**
   * Function to build info content for the BaseScreen component. This includes all
   * content such as title, subtitle, and buttons, but does not include a form.
   *
   * @returns {React.ReactNode} JSX content for the info variant of BaseScreen.
   */
  const buildInfoContent = () => (
    <div
      className={`assessment-base ${contentType} ${id}`}
      data-testid="info-element"
    >
      {title && <AssessmentTitle text={title} />}

      <div className="assessment-base__content">{children}</div>

      {(showBackButton || showButton) && (
        <div
          className={`assessment-base__button-group ${!spec?.global?.navBackButton && showBackButton ? "two-btn" : ""}`}
        >
          {!spec.global.navBackButton && showBackButton && (
            <Button onClick={onBack} className="col-span-1">
              {backButtonText}
            </Button>
          )}
          {showButton && (
            <Button onClick={onSubmit} disabled={disableContinue}>
              {buttonText}
            </Button>
          )}
        </div>
      )}

      {contentType && (contentType.includes("adbu") || contentType.includes("kickout")) && (
        <div className="assessment-base__assessment-number">
          Assessment ID <span>{assessmentNumber || state.assessmentNumber || "N/A"}</span>
        </div>
      )}
    </div>
  );

  return contentType == "form" 
    ? buildFormContent() 
    : buildInfoContent();
}

export default BaseScreen;

import React from "react";

export function join(arr) {
  if (arr.length === 0) {
    return ''
  }

  if (arr.length === 1) {
    return arr[0]
  }
  return arr.slice(0, arr.length - 1).join(', ') + ` and ${arr[arr.length - 1]}`
}

function getMedications(answers) {
  let medications = [
    ...(answers.hepatitisMeds || []),
    ...(answers.thyroidMeds || []),
    ...(answers.epilepsyMeds || []),
    ...(answers.hivMeds || []),
    ...(answers.cholesterolMeds || []),
    ...(answers.antibacterialMeds || []),
    ...(answers.otherMeds || []),
  ];
  return medications;
}

function formatMedications(medications) {
  const formatted = [];

  medications.forEach((m) => {
    m = m.replaceAll("_", " ");

    // https://ideaevolver.atlassian.net/browse/CADENCE-503
    if (m === "st johns wort") {
      m = "St John's Wort";
    }

    formatted.push(m);
  });

  return formatted;
}

// adding this back for adbu / post-assessment
function AdbuList({ answers }) {
  const flags = {
    highBMI: answers.bmi >= 35,
    otherCancer: (answers.cancer2 || []).includes("other"), // cancer is conditional so default if undefined
    highCholesterol: answers.conditions.includes("highCholesterol"),
    gallbladderDisease: answers.gallbladderDisease === "yes",
    clinicallyDiagnosedDepression: answers.diagnosedDepression === "yes",
  };

  const conditions = [];
  if (flags.otherCancer) {
    conditions.push("other cancer");
  }

  if (flags.highCholesterol) {
    conditions.push("high cholesterol");
  }

  if (flags.gallbladderDisease) {
    conditions.push("gallbladder disease");
  }

  if (flags.clinicallyDiagnosedDepression) {
    conditions.push("clinically diagnosed depression");
  }

  const medications = formatMedications(getMedications(answers))
  const hasADBU = flags.highBMI || conditions.length > 0 || medications.length > 0;

  if (!hasADBU) return null;

  return <>
    <p>Based on the information you provided, you will need to <strong>talk to a doctor</strong> about whether Zena is safe for you because:</p>
    <ul className="adbuList">
      { flags.highBMI && <li>you have a BMI greater than 35</li> }

      { conditions.length > 0 && <li className="font-bold">you have or have had {join(conditions)}</li> }

      { medications.length > 0 && <li>you are taking {join(medications)}, which may interact with Zena, so it is important to talk to a doctor before using them together</li> }
    </ul>
  </>;
}

export default AdbuList;

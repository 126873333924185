import { useState } from 'react'

import './CreateAccount.css'

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/

export const validateForm = (email, password, confirmPassword) => {
  const errors = {}
  if (!email) errors.email = 'Email is required'
  if (!emailRegex.test(email)) errors.email = 'Please enter a valid email'
  if (!password) errors.password = 'Password is required'
  if (!passwordRegex.test(password)) errors.password = 'Password must be at least 8 characters long and contain at least one letter and one number'
  if (!confirmPassword) errors.confirmPassword = 'Confirm Password is required'
  if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match'
  return errors
}

export default function CreateAccount({authClient}) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState({})

  const handleOnChange = (e) => {
    const { name, value } = e.target

    // Update the respective state
    if (name === 'email') setEmail(value)
    if (name === 'password') setPassword(value)
    if (name === 'confirmPassword') setConfirmPassword(value)

    // Validate the form fields
    const formErrors = validateForm(email, password, confirmPassword)
    setErrors(formErrors)
  }

  const handleCreateAccount = async (e) => {
    console.log('Creating account...')
    e.preventDefault()

    // Clear any previous errors
    setErrors({})

    // Validate form fields
    const formErrors = validateForm(email, password, confirmPassword)
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors)
      return
    }

    // Create user
    try {
      await authClient.createUser({ email, password })
      console.log('User created successfully!')
    } catch (e) {
      console.error(e)
    } finally {
      setEmail('')
      setPassword('')
      setConfirmPassword('')
    }
  }

  return (
    <div className='create-account'>
      <form id="create-account-form" onSubmit={handleCreateAccount}>
        <h1>Create Your  Account</h1>
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input id="email" name="email" value={email} onChange={handleOnChange} />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input id="password" name="password" value={password} onChange={handleOnChange} />
        </div>

        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input id="confirmPassword" name="confirmPassword" value={confirmPassword} onChange={handleOnChange} />
        </div>

        <div className="errors" aria-live="assertive">
          <ul>
            {Object.keys(errors).map(key => <li key={key}>{errors[key]}</li>)}
          </ul>
        </div>

        <button type="submit">Create Account</button>
      </form>
    </div>
  )
}

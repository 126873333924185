import React from 'react'

import Radio from './Radio.jsx'

import './Radio.css'

function RadioGroup({
  id,
  question,
  options = [], 
  value, 
  onEvent
}) {
  const selected = value

  if (!id) {
    console.warn(`RadioGroup is missing required id prop`);
  }

  return <div>
    { question && <p className='mb-2'>{ question }</p>}
    <div className={ `radio-group__group ${ id }` } role="radiogroup">
      { options.map((opt, i) => {
          const isSelected = selected === opt.value

          const handleSelect = () => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: opt.value})

          return (
            <Radio 
              name={ id }
              labelText={opt.labelText}
              value={opt.value}
              onChange={handleSelect}
              checked={isSelected}
              key={`radio-${ id }-${ opt.value }-${ i }`}
            />
          )
      })}
    </div>
  </div>
}

export default RadioGroup
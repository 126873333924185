import React from 'react'
import Markdown from "react-markdown";

import './Checkbox.css'

function Checkbox({
  id,
  name,
  variant = '',
  labelText,
  value,
  disabled = false,
  ariaLabel = labelText,
  onCheck,
  checked,
}) {
  return (
    <label 
      htmlFor={`${ name }-${ id || value }`} 
      className={`assessment-checkbox__container ${variant}`}
      data-disabled={disabled}>
      <input
        id={`${ name }-${ id || value }`}
        name={name}
        className='checkbox__input'
        value={value}
        type="checkbox"
        disabled={disabled}
        checked={checked}
        aria-checked={checked}
        aria-label={ariaLabel}
        onChange={(e) => onCheck(e.target.checked)}
      />
      <span className="styled-checkbox" role="presentation"></span>
      <span className="assessment-checkbox__label">
        {typeof labelText === "string" ? (
            <Markdown>{labelText}</Markdown>
          ) : typeof labelText === "object" ? (
            labelText.map((text, index) => (
              <Markdown key={index}>{text}</Markdown>
            ))
          ) : null}
      </span>
    </label>
  )
}

export default Checkbox
export function formatHeightInput(inches) {
  const feet = Math.floor(inches / 12)
  const remainingInches = inches % 12

  return `${feet} feet, ${remainingInches} inches`
}

export function formatNumericInput(a, units) {
  return units ? `${a} ${units}` : a;
}

export function formatDateInput(a) {
  const [year, month, day] = a.split("-");
  return `${month}/${day}/${year}`;
}

export function formatArray(a) {
  return a.length === 0 ? "None of these" : a.join(", ");
}

export function fmtanswer(a, type, units) {
  switch (type) {
    case "NumericInput":
      return formatNumericInput(a, units);
    case "HeightInput":
      return formatHeightInput(a);
    case "DateInput":
      return formatDateInput(a);
    case "WeightInput":
      return `${a} lbs`;
    default:
      return Array.isArray(a) ? formatArray(a) : a;
  }
}

export function filterOptions(q, value) {
  let results = [];
  const options = q?.options;

  const displayLabelText = (options, value) => {
    for (let i = 0; i < options.length; i++) {
      if (options[i].value === value) {
        if (options[i].summary) {
          results.push(options[i].summary);
          return;
        }

        // if typeof is array, push all the values
        if (Array.isArray(options[i].labelText)) {
          options[i].labelText.forEach(label => {
            results.push(label);
          });
        } else {
          results.push(options[i].labelText);
        }
      }
    }
  }

  if (typeof value === 'string' && options) {
    const values = value.split(", ");
    values.forEach(val => {
      displayLabelText(options, val)
    });
  }

  // initial bp should only display the 
  // first line of the question if there 
  // are multiple lines of text
  if(q.id === 'initialKnowBpNumbers' && results.length > 1) results.pop();

  const joined = results.join(", ");
  return joined;
}

// should return the correct index when id is found
// should return -1 when id is not found
export function index(questions, spec, id) {
  for (let i = 0; i < spec.children.length; i++) {
    const s = spec.children[i]
    const found = questions(s).find((q) => q.id === id)

    if (found) {
      return i
    }
  }
  return -1
}

// should return formatted questions when answers match
// should return empty array when answers do not match
// should handle undefined answers gracefully
// should sort questions by screenIndex
export function prepareSummaryQuestions(spec, questions, answers) {
  const ret = []

  spec.children.forEach((_screen, screenIndex) => {
    // Check if there is a manually input summary
    // Or if there is an element with a summary text flag set to true
    // I no longer enjoy this. if 2 screens have a summary, it will only show the first one
    // it just happens to have worked up until now
    const summaryText = _screen.summary || _screen.children.find(child => child.summary)?.text;

    const question = questions(_screen)
    // .filter(q => q.type !== "virtual")
    .filter(q => answers[q.id] !== undefined)
    .map(q => ({
      ...q,
      screenIndex,
      // if there is a summary text,
      // or if there is a question with a summary flag set to true
      // use that instead of the question
      question: summaryText ? summaryText : q.question?.replace(":", ""),
      value: fmtanswer(answers[q.id], q.type, q.units),
      labelText: filterOptions(q, fmtanswer(answers[q.id]))
    }))


    if (question.length === 0) return

    // yes, i know this sucks
    if(question[0].type === "BPNumbers") {
      if(answers.initialKnowBpNumbers !== "yes") return

      const systolic = question[0].value
      const diastolic = question[1].value
      const formattedBP = `${systolic} / ${diastolic}`

      // replace the systolic question with the formatted BP
      question[0].value = formattedBP

      // remove the diastolic question
      question.splice(1, 1)
    }

    // i know... i know...
    if(question.length === 2 && question[1].id === "weight") {
      question[1].question = "Current Weight"
    }


    question.forEach(q => {
      // if option is not specified, display the question,
      // but please be consistent and explicitly set 
      // showInSummary to true or false on your questions 😭
      const evalFunction = new Function(`return ${q.showInSummary}`);
      if (evalFunction() == null || evalFunction() === true) ret.push(q)
    })
  })

  return ret
}

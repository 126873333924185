import { useEffect } from 'react'
import { useNavigate, useParams, Navigate} from 'react-router-dom'

import { client } from '@galen/api-client'

import Assessment from './components/Assessment'
import DFLPage from './DFL'
import AuditContext from './audit'
import { decodeAssessmentState } from './spec/utils'
import { createComponent, components } from './components/AssessmentComponents'
import { ModalProvider } from './modal'
import { usePrompt, skipPrompt } from './prompt'
import { AssessmentProvider, useAssessment } from './context/assessmentContext'
import { questions } from './spec/utils'

export function WrappedDFL({ children }) {
  return (
    <AssessmentProvider>
      <ModalProvider>
        <DFLPage>{children}</DFLPage>
      </ModalProvider>
    </AssessmentProvider>
  )
}

export default function WrappedCodescripts(props) {
  return (
    <AssessmentProvider>
      <ModalProvider>
        <Codescripts {...props}/>
      </ModalProvider>
    </AssessmentProvider>
  )
}

function Codescripts({Components, images = {}}) {
  // navigate
  const navigate = useNavigate()
  const {
    spec, setSpec,
    state, setState,
    onEvent,
    onBack,
    onConfirm,
    onEdit,
  } = useAssessment()

  // get the assessmentId from the URL
  const { assessmentId } = useParams()

  useEffect(() => {
    client.getState({assessmentid: assessmentId})
      .then(response => {
        const remoteSpec = JSON.parse(response.spec)
        const remoteState = decodeAssessmentState(response.state)
        setSpec(remoteSpec)
        setState({
          recordType: response.recordType,
          assessmentNumber: response.assessmentnumber,
          ...remoteState,
        })
        //setAssessmentNumber(response.assessmentnumber)
      })
      .catch(e => {
        console.error("Error getting state", e)
        skipPrompt()
        navigate('/')
      })
  }, [])


  const hasOutcomeComponent = !!Components.Outcome
  // this is the exit modal
  const whenToShowExitModal = !state.isKickout && (state.outcome != 'ok' || hasOutcomeComponent)
  usePrompt("one", whenToShowExitModal, onEvent, state)

  let assessmentScreen = undefined
  if (state.isTaking || state.isEditing) {
    assessmentScreen = spec.children[state.index]
  }

  let dnuScreen = undefined
  if (state.isKickout) {
    dnuScreen = spec.kickoutScreens.filter(x => x.id == state.kickoutReason)[0]
  }

  let currentScreen = assessmentScreen || dnuScreen

  const canGoBack = state.isTaking || state.isEditing
  const showingBackButton = currentScreen && !!currentScreen.showBackButton
  const showBackButton = showingBackButton && canGoBack

  const onComponentEvent = e => {
    let ev = e
    if (state.isTaking || state.isEditing) {
      const screenId = spec.children[state.index].id
      ev = {...ev, screenId}

      if (e.type == "MOREINFO_DISPLAYED") {
        const componentId = `${screenId}.moreInfo`
        ev = {...ev, screenId, componentId}
      }
    }
    onEvent(ev)
  }

  const layoutProps = {
    state,
    spec,
    onBack,
    showBackButton,
  }

  const renderContent = () => {
    if (state.isReviewing) {
      return <Components.Summary
        onConfirm={onConfirm}
        onEdit={onEdit}
        onEvent={onEvent}
        spec={spec}
        state={state}
        questions={questions}
      />
    }

    if (state.isKickout) {
      return createComponent(
          spec.kickoutScreens.filter(
            (x) => x.id == state.kickoutReason
          )[0],
          { onEvent, state, answers: state.answers, images },
        {...components, ...Components.AssessmentComponents},
      )
    }

    if (state.isPostCompleted && state.outcome == 'ok') {
      let dst = `/assessment/outcome/${assessmentId}`
      if (state.recordType == "clinician") {
        dst += "?clinician=1"
      }
      return (<Navigate to={dst}/>)
    }

    return (<Assessment assessmentComponents={Components.AssessmentComponents} images={images}/>)
  }

  if (state.isPostCompleted && state.outcome == 'ok') {
    if (Components.Outcome) {
      return (<Components.Outcome state={state}/>)
    }
  }

  return (
    <AuditContext.Provider value={onComponentEvent}>
      <Components.Layout {...layoutProps}>
        { !spec.loading && !state.loading && (
          <div className="assessment-content">
            { renderContent() }
          </div>
        )}
      </Components.Layout>
    </AuditContext.Provider>
  )
}

export { 
  WrappedDFL as DFLScreen,
  DFLPage,
}

import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'

import { subscribe } from '../subscribers'
import { authClient } from '@galen/api-client'
//import store from '../store'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faAt } from '@fortawesome/free-solid-svg-icons'
import { faApple, faGoogle, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons'

import ModalContext from '../modal'

import './Auth.css'

export const loaded = {}

export function load(src, cb) {
  if (loaded[src]) {
    cb()
    return
  }

  loaded[src] = true

  const script = document.createElement('script')
  script.src = src
  script.onload = () => {
    if (cb) cb()
  }

  document.body.appendChild(script)
}

export let didAppleInit = false

export function makeAppleInit(config) {
  function initApple() {
    if (!config.APPLE_CLIENT_ID) {
      return
    }

    if (!didAppleInit) {
      didAppleInit = true
      window.AppleID.auth.init({
        clientId: config.APPLE_CLIENT_ID,
        scope : 'name email',
        redirectURI : `${window.location.origin}/auth/apple/success`,
        //state : '[STATE]',
        //nonce : '[NONCE]',
        usePopup : true,
      });
    } else {
      //window.AppleID.auth.renderButton()
    }
  }

  return initApple
}

export function popup(target, name) {
  const q = function (e, t) {
    var i = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width,
      o = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;
    return {
      left: i / 2 - e / 2 + window.screenLeft,
      top: o / 2 - t / 2 + window.screenTop,
    };
  };
  const top = q(700,700).top
  const left = q(700,700).left
  const features = `width=700,height=700,top=${top},left=${left},resizable=no,location=no,menubar=no`
  window.open(target, name, features)
}

export function googleAuth(mode, transfer=false) {
  //store.dispatch({type: "GOOGLE_AUTH_START", mode, transfer})
  popup(buildGoogleRedirect(), "GoogleAuth")
  return subscribe("google")
}

export function appleAuth(mode, transfer=false) {
  //store.dispatch({type: "APPLE_AUTH_START", mode, transfer})
  window.AppleID.auth.signIn()
  return subscribe("apple")
}

export function buildGoogleRedirect() {
  const params = {
    scope: "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    access_type: "offline",
    redirect_uri: window.location.origin + "/oauth/google",
    response_type: "code",
    client_id: "934195914927-gs5ceipt1i17l9tfhc3ab2nlbv5m6hf5.apps.googleusercontent.com",
    
    prompt: "consent",
  }
  const base = "https://accounts.google.com/o/oauth2/v2/auth"

  const qs = Object.entries(params).map(([k,v]) => `${k}=${v}`).join("&")
  return encodeURI(`${base}?${qs}`)
}

export function GuestLoginConfirmationModal({ onConfirm, onCancel }) {
  return <div className="guest-modal-content">
    <div className="guest-modal-body">
      <h2>Continue as a Guest?</h2>
      <p>You will have to re-enter your information each time you want to purchase Zena if you continue as a guest.</p>
      <p><strong>You will also have a chance to create an account later.</strong></p>
    </div>

    <div className="guest-modal-buttons">
      <button className="button" onClick={() => onCancel()}>Go Back</button>
      <button className="button" onClick={onConfirm}>Confirm</button>
    </div>
  </div>
}

export function SocialButton({ type, children, onClick }) {
  const icon = () => {
    switch (type) {
      case "apple":
        return <FontAwesomeIcon icon={faApple} />
      case "email":
        return <FontAwesomeIcon icon={faAt} />
      case "facebook":
        return <FontAwesomeIcon icon={faFacebook} />
      case "google":
        return <FontAwesomeIcon icon={faGoogle} />
      case "linkedin":
        return <FontAwesomeIcon icon={faLinkedin} />
      case "guest":
        return <FontAwesomeIcon icon={faUser} />
      default:
        return null
    }
  }

  return <button
    className="social-button"
    aria-label={ children }
    onClick={() => onClick(type) } 
    testid={`${type}-sign-in`}
  >
      { icon() }
      { children }
    </button>
}

export function EmailPasswordForm() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    // try {
      console.log("email", email)
      console.log("password", password)
    // } catch (error) {
    //   setError(error.message)
    // }
    setLoading(false)
  }

  return <>
    <form onSubmit={handleSubmit} className='email-password-form' data-testid="email-password-form">
      <input
        type='email'
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder='Email'
      />

      <input
        type='password'
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder='Password'
      />

      <button type='submit' disabled={loading}>
        {loading ? "Loading..." : "Sign In"}
      </button>

      {error && <p>{error}</p>}
    </form>
  </>
}

export function Auth({ onSubmit, authSupport = {}, confirmGuest = true }) {
  // const config = useSelector(state => state.config)
  const { setModal } = useContext(ModalContext)
  const [showEmailPasswordForm, setShowEmailPasswordForm] = useState(false)

  const handleGuestLogin = () => {
    if (!confirmGuest) {
      return onSubmit()
    }

    return setModal(() => (
      <GuestLoginConfirmationModal 
        onConfirm={handleConfirmGuest} 
        onCancel={() => setModal(null)} 
      />
    ), { className: 'confirm-modal', type: 'confirm' });
  };

  const handleConfirmGuest = async () => {
    onSubmit()
    setModal()
  }

  const authHandlers = {
    apple: async (mode) => {
      await appleAuth(mode);
    },
    facebook: async () => {
      //await facebookAuth();
      console.warn("Facebook auth not supported")
    },
    google: async (mode) => {
      await googleAuth(mode);
    },
    email: () => {
      setShowEmailPasswordForm(true)
    }
  };

  const authOrder = [
    'google', 
    'apple', 
    'facebook', 
    'linkedin', 
    'email'
  ];

  // useEffect(() => {
  //   load("https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js", makeAppleInit(config))
  //   //load("https://accounts.google.com/gsi/client", initGoogle)
  // }, [config])

  if (showEmailPasswordForm) {
    return <>
    <EmailPasswordForm />

    <button className="options-btn" type="button" onClick={() => setShowEmailPasswordForm(false)}>
      Choose another method
    </button>
  </>
  }

  return (
    <div className='auth-container' role='group'>
      {authOrder.map((authType) => (
        authSupport[authType] && (
          <SocialButton
            key={authType}
            type={authType}
            onClick={authHandlers[authType]}
          >
            Continue with {authType.charAt(0).toUpperCase() + authType.slice(1)}
          </SocialButton>
        )
      ))}

      <div>
        OR <span className='sr-only'>continue as a guest</span>
      </div>

      {authSupport.guest && (
        <SocialButton
          type="guest"
          onClick={handleGuestLogin}
        >
          Continue as a Guest
        </SocialButton>
      )}
    </div>
  );
}


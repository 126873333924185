import { Routes, Route, Outlet, Navigate } from 'react-router-dom'

// https://github.com/remix-run/react-router/issues/8264#issuecomment-991271554
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import history from './history'

import Modal from '@galen/react-modal';
import { ModalProvider } from './modal'

// pages and components
import AssessmentPage from './pages/Assessment'
import Checkout from './pages/Checkout'
import Confirmation from './pages/Confirmation';
import CreateAccount from './pages/CreateAccount';
import LandingPage from './pages/LandingPage';
import OfflineNotification from './components/OfflineNotification/OfflineNotification';
import Outcome from './pages/Outcome'
import SignIn from './pages/SignIn';

// hooks
import useConfig from './hooks/useConfig';
import useNetworkStatus from './hooks/useNetworkStatus';

import 'tailwindcss/tailwind.css'; // Import Tailwind first
import './index.css'; // Then import base custom CSS

// project-specific styles for Codescripts components
import './components/Accordion/Accordion.css'
import './components/Auth.css'
import './components/Buttons/Buttons.css'
import './components/Checkbox/Checkbox.css'
import './components/Modals/Modals.css'
import './components/NumericInput/NumericInput.css'
import './components/OptionSelect/OptionSelect.css'
import './components/Radio/Radio.css'

// project-specific styles for questions and kickout screens
// overrides should be located in the 
// _Questions folder or the _Kickouts folder
import './pages/Summary/Summary.css'
import './pages/_Questions/SignUpScreen.css'
import './pages/_Questions/BMIObesityScreen.css'
import './pages/_Kickouts/kickouts.css'

Modal.setAppElement(document.getElementById('root'))

export function Toasts() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={2000}
      hideProgressBar={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Slide}
    />
  )
}

function App() {
  useConfig()
  const isOnline = useNetworkStatus()

  return (

      <div className='App'>
        <HistoryRouter history={history}>
          <Routes>
            <Route path="/" element={<ModalProvider><Outlet/></ModalProvider>}>
              <Route index element={<ModalProvider key="landing"><LandingPage/></ModalProvider>} />
              <Route path="sign-in" element={<SignIn/>} />
              <Route path="create-account" element={<CreateAccount/>} />
              <Route path="assessment" element={<Navigate to="/" replace />} />
              <Route path="assessment/:assessmentId/" element={<ModalProvider key="assessment"><AssessmentPage/></ModalProvider>} />
              <Route path="assessment/outcome/:assessmentId" element={<Outcome />} />
              <Route path="checkout" element={<Checkout />} />
              <Route path="confirmation" element={<Confirmation />} />
              {/* <Route path="return/:assessmentId" element={<ReturnPage/>} /> */}
            </Route>
          </Routes>
        </HistoryRouter>

        <Toasts/>

        <ModalProvider showCloseButton={false} canClose={false}>
          <OfflineNotification isOnline={isOnline} />
        </ModalProvider>
      </div>
  )
}

export default App

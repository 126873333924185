import React, { useContext } from "react"
import { useAudit } from '@galen/codescripts/audit'
import ModalContext from "../../modal"
import { prepareSummaryQuestions } from "./SummaryUtils"
import SummaryItem from "./SummaryItem/SummaryItem"
import ConfirmModal from "./ConfirmModal/ConfirmModal"
import edit from '../../icn/edit.svg'
import './Summary.css'

function Summary({ onConfirm, onEdit, onEvent, spec, state, questions = () => {} }) {
  useAudit({type: "SUMMARY_SCREEN_DISPLAYED"})
  const { answers } = state
  const { repurchase } = answers

  const qs = prepareSummaryQuestions(spec, questions, answers)
  const setModal = useContext(ModalContext)

  const handleOnEdit = (e) => {
    onEdit(e.currentTarget.value)
  }

  const showModal = () => {
    onEvent({type: "SUMMARY_CONFIRM_CLICK"})
    setModal(() => (
      <ConfirmModal
        onEvent={onEvent}
        onConfirm={onConfirm}
        setModal={setModal}
      />
    ))
  }

  return <div className="summary">
    <div className="w-full">
      <h1 className="summary__header">
        { repurchase 
          ? "Has anything changed since the last time you visited?"
          : "Please check if your answers were recorded correctly."
        }
      </h1>

      { !repurchase && <p className="my-8 text-lg">
        Click <img className="inline-block" src={ edit } alt="edit icon"/> if needed to edit.
        </p> }

      <div className="summary__container">
        { qs.map((q) => <SummaryItem q={q} handleOnEdit={handleOnEdit} key={q.id} editIcon={edit} />) }
      </div>
    </div>

    <div className="summary-button-container">
      <button className="button summary-button" onClick={showModal}>Confirm</button>
    </div>
  </div>
}

export default Summary
// import { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

import NavBar from "../components/NavBar/NavBar"

function Confirmation() {
  // const [customerEmail, setCustomerEmail] = useState(() => {
  //   // check session storage for customerEmail
  //   return sessionStorage.getItem('customerEmail') || '';
  // });

  // const [sessionId, setSessionId] = useState(() => {
  //   // check session storage for sessionId
  //   return sessionStorage.getItem('sessionId') || '';
  // });

  return <>
    <NavBar />
    <section id="success" className='p-2 md:p-4 container mx-auto text-center'>
      <h1 className="md:mb-2 font-semibold text-center text-xl tracking-widest uppercase text-primary-700">Checkout</h1>
      <div className='w-full grid place-items-center my-8 text-green-700'>
        <FontAwesomeIcon icon={faCircleCheck} size="3x" />
      </div>
      <h2 className='text-center mb-4 font-semibold'>Thank you for your purchase!</h2>
      <p className='mb-2'>We appreciate your business! A confirmation email will be sent to <strong>example@email.com</strong>.</p>
      <p>If you have any questions, please email <a className='text-blue-500 underline' href="mailto:orders@example.com">orders@example.com</a>.</p>
    </section>
  </>
}

export default Confirmation
import React from 'react';

import OptionSelect from "./OptionSelect"

const START_HEIGHT_INCHES = 58; // Starting height in inches (4 feet, 10 inches)
const NUM_HEIGHT_OPTIONS = 27;  // Number of height options to generate
const INCHES_PER_FOOT = 12;     // Number of inches in a foot

function HeightSelect({ id, onEvent }) {
  const optionRange = Array.from({ length: NUM_HEIGHT_OPTIONS }, (_, index) => {
    const value = START_HEIGHT_INCHES + index;
    const feet = Math.floor(value / INCHES_PER_FOOT);
    const inches = value % INCHES_PER_FOOT;

    return {
      value,
      text: `${feet} feet, ${inches} inches`,
    };
  });

  const handleChange = (value) => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value[id] })
  }

  return <OptionSelect id={id} options={optionRange} onChange={handleChange} />
}

export default HeightSelect;
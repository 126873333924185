import React, { useState } from 'react'

import './Date.css'

function DatePicker({
  id,
  name,
  labelText,
  placeholder,
  ariaLabel = labelText || placeholder || "Date",
  value =  "",
  err,
  onEvent,
  disabled = false,
  minDate = "1900-01-01",
  maxDate = "2100-01-01",
}) {
  const [date, setDate] = useState(value || "");
  const [error, setError] = useState(err);

  function formatDate(dateString) {
    const [year, month, day] = dateString.split('-');
    const date = new Date(year, month - 1, day);
    const formattedMonth = (1 + date.getMonth()).toString().padStart(2, '0');
    const formattedDay = date.getDate().toString().padStart(2, '0');
  
    return formattedMonth + '/' + formattedDay + '/' + year;
  }

  const onChange = (e) => {
    setError('')

    let v = e.target.value

    if (new Date(v) < new Date(minDate) || new Date(v) > new Date(maxDate)) {
      setError(`Please enter a date between ${formatDate(minDate)} and ${formatDate(maxDate)}`);
    }

    setDate(v);
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: v })
  }

  return (
    <label htmlFor={`${name}-${id}`} className='assessment-date-input__container' data-disabled={disabled}>
      <span className="assessment-date-input__label">{ labelText }</span>
      <input
        id={`${name}-${id}`}
        name={name}
        className='assessment-date-input__input'
        value={date}
        type="date"
        disabled={disabled}
        aria-label={ariaLabel}
        placeholder={placeholder}
        onChange={(e) => onChange(e) }
      />
      { error && <span className="assessment-date-input__error" aria-live="assertive">{ error }</span> }
    </label>
  )
}

export default DatePicker
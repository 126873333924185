import { useContext, useEffect, useCallback } from 'react';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

import ModalContext from './modal'


export function useBlocker( blocker, when = true ) {
  const { navigator } = useContext( NavigationContext );

  useEffect( () => {
    if ( ! when ) return;

    const unblock = navigator.block( ( tx ) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker( autoUnblockingTx );
    } );

    return unblock;
  }, [ navigator, blocker, when ] );
}


let skipNext = false

export function skipPrompt() {
  skipNext = true
}

export function usePrompt(msg, when = true, onEvent) {
  const { setModal, isModalVisible, modalType } = useContext(ModalContext);

  const blocker = useCallback(
    ( tx ) => {
      if (skipNext) {
        skipNext = false
        tx.retry()
        return
      }

      const onClose = () => {
        setModal();
      };

      const onConfirm = () => {
        skipPrompt()
        setModal();
        onEvent({type: "EXIT_CONFIRM"})
        tx.retry()
      }

      const createModal = () => (
        <div className='exit-modal-content'>
          <div className='exit-modal-body'>
            <h2 className=''>Exit Survey</h2>
            <p>You are about to leave the health survey.</p>
            <p>If you leave, your information will not be saved.</p>
            <p className='text-center font-bold'>Are you sure you want to exit?</p>
          </div>

          <div className='exit-modal-buttons'>
            <button className='button button-danger' onClick={onConfirm}>Leave</button>
            <button className='button button-primary' onClick={onClose} variant="secondary full">Close</button>
          </div>
        </div>
      )

      setModal(createModal, { className: 'exit-modal', type: 'exit' });
    },
    [msg, onEvent, setModal, isModalVisible, modalType]
  );

  useBlocker(blocker, when);
}

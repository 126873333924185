import React from 'react'
import Markdown from 'react-markdown'

import './List.css'

function List({ ordered = false, options }) {
  return ordered 
    ? <ol>
        {options.map((opt) => (
          <li key={`comp-ordered-item-${opt}`}>
            <Markdown>{opt}</Markdown>
          </li>
        ))}
      </ol>
    : <ul>
        {options.map((opt) => (
          <li key={`comp-unordered-item-${opt}`}>
            <Markdown>{opt}</Markdown>
          </li>
        ))}
      </ul>
}

export default List
import React, { useState, createContext, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import { client } from '@galen/api-client';
import { decodeAssessmentState, encodeScreenState } from '../spec/utils'

const AssessmentContext = createContext();

export const AssessmentProvider = ({ children, initialState }) => {
  const { assessmentId } = useParams();
  const navigate = useNavigate()

  const initialSpec = { children: [{ type: "Screen", id: "loading" }], loading: true };
  const initialDefaultState = {
    answers: {},
    completed: false,
    editIndex: 0,
    postIndex: 0,
    index: 0,
    isCompleted: false,
    isPostCompleted: false,
    isEditing: false,
    isKickout: false,
    isOutcome: false,
    isReviewing: false,
    isTaking: true,
    kickoutReason: "",
    outcome: "",
    state: "assessment",
    dflRead: false,
    loading: true,
  };

  const [spec, setSpec] = useState(initialSpec);
  const [state, setState] = useState(initialState || initialDefaultState);

  const onSubmit = (answers) => {
    const encoded = encodeScreenState(answers)
    client.submitScreen({assessmentid: assessmentId, screenstate: encoded})
      .then(response => {
        const decoded = decodeAssessmentState(response.state)
        setState({...state, ...decoded})
      })
      .catch(e => console.error(e))
  }

  const onEdit = (index) => {
    client.editScreen({assessmentid: assessmentId, index: index})
      .then(response => {
        const nextState = decodeAssessmentState(response.state)
        setState({...state, ...nextState})
      })
      .catch(e => console.error(e))
  }

  const onReturnEdit = (screenId, answers) => {
    return client.returningEdit({assessmentId, screenId, answers: JSON.stringify(answers)})
      .then(response => {
        const nextState = decodeAssessmentState(response.state)
        setState({...state, ...nextState})
      })
  }

  const onConfirm = () => {
    client.finishAssessment({recordid: assessmentId})
      .then(res => {
        const decoded = decodeAssessmentState(res.state)
        console.log("onConfirm decoded", decoded)
        // set state to decoded properties, preserve state otherwise
        setState({...state, ...decoded})
      })
      .catch(e => console.error(e))
  }

  const onBack = () => {
    if (state.isKickout || (state.isOutcome && state.isPostCompleted) || state.state == "returningedit") {

      navigate('/')
      return
    }

    if (state && state.index === 0) {
      navigate('/')
      return
    }

    client.goBack({assessmentid: assessmentId})
      .then(res => {
        const decoded = decodeAssessmentState(res.state)
        setState({...state, ...decoded})
      })
      .catch(e => console.error(e))
  }

  const onEvent = (e) => {
    client.recordEvent({assessmentid: assessmentId, eventtype: e.type, data: JSON.stringify(e)})
      // .catch(e => console.error(e))
  }

  return (
    <AssessmentContext.Provider value={{
      assessmentId,
      spec, setSpec,
      state, setState,
      onSubmit,
      onEdit,
      onReturnEdit,
      onConfirm,
      onBack,
      onEvent,
    }}>
      {children}
    </AssessmentContext.Provider>
  );
};

export const useAssessment = () => useContext(AssessmentContext);

import { useState } from "react";

import NavBar from "../components/NavBar/NavBar";

import "./SignIn.css";

function SignIn() {
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: ""
  });
  const [formParams, setFormParams] = useState({
    email: "",
    password: ""
  });

  const validateParams = (params) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    let errors = {
      email: "",
      password: ""
    };

    if (formParams.email === "") {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formParams.email)) {
      errors.email = "Invalid email";
    }

    if (!formParams.password) {
      errors.password = "Password is required";
    }

    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    // clear form errors
    setFormErrors({ email: "", password: "" });

    const errors = validateParams(formParams);
    if (errors.email || errors.password) {
      setFormErrors(errors);
      return;
    }

    console.log("Form submitted", formParams);
  }

  return <>
    <NavBar />
    <div className="signin-page">
      <div className="container mx-auto grid place-items-center">
        <form className="signin-form" onSubmit={handleSubmit}>
          <h1>Sign In</h1>

          <label className="form-control" htmlFor="email">
            <div className="label">
              <span className="label-text">Email</span>
            </div>
            <input type="text" className="input" id="email" name="email" value={formParams.email} onChange={(e) => setFormParams({...formParams, email: e.target.value})} />

            {formErrors.email && <div className="label">
              <span className="label-text text-error">{formErrors.email}</span>
            </div>}
          </label>

          <label className="form-control" htmlFor="password">
            <div className="label">
              <span className="label-text">Password</span>
            </div>
            <input type="password" className="input" id="password" name="password" value={formParams.password} onChange={(e) => setFormParams({...formParams, password: e.target.value})} />

            {formErrors.password && <div className="label">
              <span className="label-text text-error">{formErrors.password}</span>
            </div>}
          </label>

          <button className="button button-primary">Sign In</button>
        </form>
      </div>
    </div>
  </>
}

export default SignIn;
import { useEffect, useState } from 'react';

const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const updateNetworkStatus = () => {
    setIsOnline(navigator.onLine);
  };

  useEffect(() => {
    updateNetworkStatus(); // Set initial status

    window.addEventListener('online', updateNetworkStatus); // Add online event listener
    window.addEventListener('offline', updateNetworkStatus); // Add offline event listener

    return () => {
      window.removeEventListener('online', updateNetworkStatus); // Cleanup online event listener
      window.removeEventListener('offline', updateNetworkStatus); // Cleanup offline event listener
    };
  }, []);

  return isOnline;
};

export default useNetworkStatus;

import React from 'react'

import './ConfirmButton.css'

export default function ConfirmButton({ id = "confirm-button", onConfirm, ...rest }) {
  return <button id={ id } className="confirm-button" onClick={onConfirm} {...rest} data-testid={id}>
    <svg viewBox="0 0 32 32" className="h-6 w-6" role="presentation">
      <rect height="32" width="32" className="fill-current text-primary-500" />
      <path 
        d="M7 14 L13 20 L23 10" 
        stroke="white" 
        strokeWidth="4" 
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  </button>
}
import './ConfirmModal.css'

function ConfirmModal({ onEvent, onConfirm, setModal }) {
  const handleOnConfirmModal = () => {
    onEvent({type: "SUMMARY_CONFIRM_MODAL_CONFIRM"})
    onConfirm()
    setModal()
  };

  const handleOnCancelModal = () => {
    setModal()
  };

  return (
    <div className="confirm-answers-modal">
      <h2>Confirm your answers</h2>

      <p>By clicking <strong>Finish</strong>, I confirm that all information I have provided is truthful and accurate to the best of my knowledge.</p>

      <div className="confirm-answers-buttons">
        <button className='button secondary' onClick={handleOnCancelModal}>Go Back</button>
        <button className='button' onClick={handleOnConfirmModal}>Finish</button>
      </div>
    </div>
  )
}

export default ConfirmModal
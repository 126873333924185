import React, { useState, useEffect, useMemo } from "react"
import NavBar from "./NavBar/NavBar"
import logo from "../img/logo.png"
import caution from "../img/caution.png"

import "./Layout.css"

export default function LayoutScreen({
  children,
  state,
  spec,
  onBack,
  showBackButton = false,
  showProfile
}) {
  const [sideImg, setSideImg] = useState("")

  const isCautionState = useMemo(() => (
    state?.isOutcome || 
    state?.isKickout || 
    (!state?.isReviewing 
      && state?.isCompleted 
      && state?.outcome === "" 
      && state?.kickoutReason !== ""
    )
  ), [state])

  useEffect(() => {
    if (isCautionState) {
      setSideImg(caution)
    } else {
      setSideImg(logo)
    }
  }, [isCautionState])

  // scroll to top on state change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [state])

  return (
    <div className="app-container">
      <NavBar onBack={onBack} showProfile={showProfile} showBackButton={ spec?.global?.navBackButton && showBackButton } />
      <main className="relative">
        <div className={`zena-bar ${isCautionState ? 'kickout' : ''}`} role="presentation" aria-hidden="true">
          <img src={sideImg} alt="" />
        </div>
        <div className={`${isCautionState ? 'lg:ml-[436px]' : 'lg:ml-[295px]'}`}>
          {children}
        </div>
      </main>
    </div>
  )
}
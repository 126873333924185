import React from "react"
import { MoreInfoIcon } from "../MoreInfo"

import './Banner.css'

function Banner({ text, text2, style, list, modal, children }) {
  return (
    <section className="banner banner-primary" style={style}>
      <span>{text}</span>
      {text2 && <span>{text2}</span>}

      { modal && children && <MoreInfoIcon children={children} /> }
 
      {list && (
        <div className="banner-list">
          <ul>
            {list.map((item) => (
              <li key={item}>{item}</li>
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}

export default Banner
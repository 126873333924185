import React from 'react';
import Markdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'

export const ParagraphRenderer = (props) => {
  return React.createElement('p', { 
    id: props.id,
    className: props.className
  }, props.children);
}

export const ParagraphFunc = ({ props, anchorId, className }) => {
  return <ParagraphRenderer {...props} id={anchorId} className={className} />
}

function Paragraph({ text, anchorId, className }) {
  if (text === undefined) {
    console.warn('Paragraph: text is undefined');
    return null;
  }

  return <Markdown 
    children={text}
    remarkPlugins={[remarkBreaks]}
    components={{
      p: (props) => ParagraphFunc({ props, anchorId, className })
    }}
  />
}

export default Paragraph;
import './ProgressBar.css'

function ProgressBar({ current, total }) {
  const progress = (current / total) * 100

  return (
    <div
      className='app-progress' 
      role="progressbar" 
      aria-valuemin="0"
      aria-valuemax="100"
      aria-valuenow={progress}
      aria-label={`Progress: ${progress}%`}
    >
      <div
        className="h-full w-[var(--progress)] bg-teal-600" 
        style={{ "--progress": `${progress}%` }}
      />
    </div>
  )
}

export default ProgressBar
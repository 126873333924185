import React, { useState } from 'react'
import { Modal } from '../../modal'

export default function ConfirmButtons({ id, onSubmit, onEvent, value, children }) {
  const [isOpen, setIsOpen] = useState(false)

  // const handleConfirm = (e) => {
  //   onEvent({type: "CONFIRM", componentId: `${id}Confirm`})
  //   onSubmit()
  // }

  const onYes = () => {
    setIsOpen(true)
    onEvent({
      componentId: id,
      type: "UPDATED_ANSWER",
      newValue: "yes",
    });
  }

  const onNo = () => {
    onSubmit({ [id]: "no" });
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return (
    <div className="space-y-4">
      <Modal className="bg-white pt-0 rounded shadow" isOpen={isOpen} onRequestClose={closeModal}>
        {children}
      </Modal>

      {/* TODO: needs custom yes/no button logic to pop modals and stuff */}
      <div className="grid grid-cols-2 gap-4">
        <button
          className="button button-primary"
          value="yes"
          onClick={onYes}
        >
          Yes
        </button>
        <button
          className="button button-primary"
          value="no"
          onClick={onNo}
        >
          No
        </button>
      </div>
    </div>
  );
}

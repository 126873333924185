import NavBar from "../components/NavBar/NavBar"


import './Checkout.css'

function Checkout() {
  return <div data-testid="checkout-page">
    <NavBar />
    <h1>Checkout</h1>
  </div>
}

export default Checkout

// import { useEffect, useState } from "react"
// import { useNavigate, useSearchParams } from "react-router-dom"
// import { useSelector } from 'react-redux'
// import NavBar from "../components/NavBar/NavBar"

// import { checkoutClient } from "@galen/api-client"

// import './Checkout.css'

// MOCK CHECKOUT TO DEMONSTRATE A CHECKOUT EXPERIENCE. NOT FOR PRODUCTION
// function Checkout() {
//   const [searchParams, setSearchParams] = useSearchParams();
//   const config = useSelector(state => state.config)
//   const [checkoutSession, setCheckoutSession] = useState()
//   const navigate = useNavigate()
//   // get token object from session storage
//   const token = JSON.parse(sessionStorage.getItem('token'))

//   useEffect(() => {
//     checkoutClient.beginCheckoutSession({assessmentId: searchParams.get("assessmentId")})
//       .then(({token}) => setCheckoutSession(token))
//   }, [])

//   useEffect(() => {
//     const purchaseListener = (event) => {
//       if (event.origin !== config.CHECKOUT_URL) return;

//       if (event.data.action === 'checkoutComplete') {
//         sessionStorage.setItem('checkoutComplete', 'true'); // Corrected value to be a string
//         sessionStorage.setItem('customerEmail', event.data.customerEmail);
//         sessionStorage.setItem('sessionId', event.data.sessionId);

//         navigate('/confirmation');
//         window.removeEventListener('message', purchaseListener);
//       }
//     };

//     window.addEventListener('message', purchaseListener);


//     return () => window.removeEventListener('message', purchaseListener);
//   }, [config]);

//   useEffect(() => {
//     if (sessionStorage.getItem('checkoutComplete') === 'true') {
//       navigate('/');
//     }
//   }, [navigate]);


//   return <div className="checkout-page" data-testid="checkout-frame">
//     <NavBar />

//     <div className="checkout-container">
//       { checkoutSession && (
//         <iframe
//           title="Checkout"
//           id="checkout-frame"
//           src={`${config.CHECKOUT_URL}?session=${checkoutSession}`}
//         />
//       )}
//     </div>
//   </div>
// }

// export default Checkout

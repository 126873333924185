import React, { useState, useEffect } from 'react'

import './BirthYearInput.css'

function BirthYearInput({
  id,
  name,
  question,
  placeholder = '',
  disabled,
  err,
  hideErrs,
  value = '',
  onEvent,
}) {
  const [inputValue, setInputValue] = useState(value)
  const [errors, setErrors] = useState([]);
  const [hasErrors, setHasErrors] = useState(false);

  const handleOnChange = (e) => {
    // Remove any spaces from the input value also
    // remove any [a-zA-Z] characters from the input value
    const value = e.target.value.replace(/[^0-9]/g, '');

    setInputValue(value);
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value });
  }

  const handleOnFocus = () => {
    hideErrs();
    onEvent({ componentId: id, type: "INPUT_SELECTED" })
  };
  const handleOnBlur = () => onEvent({ componentId: id, type: "INPUT_DESELECTED" });

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value])

  useEffect(() => {
    setHasErrors(err ? true : false);
    setErrors(() => {
      if (!err) return [];
      return [err];
    })
  }, [err]);

  return (
    <div className={`assessment-numeric-input ${hasErrors ? "has-error" : ""}`} data-disabled={disabled}>
      { question && (
        <label htmlFor={id} className="assessment-numeric-input__label">
          { question }
        </label>
      )}

      <div className={`assessment-numeric-input__input-units 'has-units`}>
        <input 
          id={id}
          name={ name || id }
          className={`assessment-numeric-input__input text-left`}
          value={ inputValue }
          type="text"
          maxLength={4}
          placeholder={ placeholder }
          disabled={ disabled }
          aria-invalid={ hasErrors }
          aria-label="birth year"
          aria-valuenow={ inputValue }
          onChange={ handleOnChange }
          onFocus={ handleOnFocus }
          onBlur={ handleOnBlur }
          role="spinbutton"
          data-1p-ignore
        />
      </div>

      { hasErrors && <ul className="assessment-numeric-input__error" aria-live="assertive">
          { <li>{ errors[0] }</li> }
        </ul> }
    </div>
  )
}

export default BirthYearInput;

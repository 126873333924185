import React from 'react';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import rehypeRaw from 'rehype-raw';

export function preprocessMarkdown(text) {
  // Replace only the underscores that are likely not part of a word (simple regex, adjust as needed)
  return text.replace(/(^|[^*\w])_([^_]+)_($|[^*\w])/g, '$1<u>$2</u>$3');
}

/**
 * HeadingRenderer is a custom renderer
 * It parses the heading and attaches an id to it to be used as an anchor
 */
export const HeadingRenderer = (props) => {
  return React.createElement('h' + props.level, { 
    id: props.id,
    className: props.className
  }, props.children);
};

export const ParagraphRenderer = (props) => {
  return React.createElement('p', { 
    id: props.id,  
    className: props.className
  }, props.children);
};

export const UnderlineRenderer = (props) => {
  // Apply underlining through CSS class
  return <span className='underline'>{props.children}</span>;
};

// make sure this id is unique
function MarkdownComponent({ className, anchorId, text }) {
  const preprocessedText = preprocessMarkdown(text);

  return <Markdown 
  children={preprocessedText}
  remarkPlugins={[remarkGfm, remarkBreaks]}
  rehypePlugins={[rehypeRaw]}
  components={{
    h1: (props) => <HeadingRenderer {...props} id={anchorId} className={className} />,
    h2: (props) => <HeadingRenderer {...props} id={anchorId} className={className} />,
    h3: (props) => <HeadingRenderer {...props} id={anchorId} className={className} />,
    h4: (props) => <HeadingRenderer {...props} id={anchorId} className={className} />,
    p: (props) => <ParagraphRenderer {...props} id={anchorId} className={className} />,
    u: UnderlineRenderer,
  }} />
}

export default MarkdownComponent;
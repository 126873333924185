import React from 'react'
import Markdown from 'react-markdown'
import remarkBreaks from 'remark-breaks'
import './ConfirmButton.css'

export default function ConfirmButton({ onConfirm, ...rest }) {
  return (
      <button className="confirm-button" onClick={onConfirm} {...rest} data-testid="confirm-button">
      <svg viewBox="0 0 32 32" className="h-6 w-6" role="presentation">
        <rect height="32" width="32" className="fill-current text-primary-500" />
        <path 
          d="M7 14 L13 20 L23 10" 
          stroke="white" 
          strokeWidth="4" 
          strokeLinecap="round"
          fill="none"
        />
      </svg>
    </button>
  )
}

export function ConfirmModal({ id, event, onGoBack, onSubmit, onEvent, title, text, children }) {
  const handleConfirm = () => {
    //onEvent({type: "CONFIRM", componentId: event})
    onSubmit({[id]: true})
  }

  return (
    <div className="modal-body confirm-modal">
      { children.map((child, index) => {
        return (
          <div key={index} className='confirm-modal-content'>
            <Markdown 
              children={child}
              remarkPlugins={[remarkBreaks]}
            />
          </div>
        )
      }) }

      <div className="confirm-answers">
        <ConfirmButton onConfirm={handleConfirm} aria-labelledby="confirm-answers" />
        <p id="confirm-answers-text">
          {text}
        </p>
      </div>

    </div>
  )
}

import React, { Component } from 'react';
import ReactModal from '@galen/react-modal';

const ModalContext = React.createContext(0);

export function CloseButton({ onClick }) {
  return (
    <button className="btn-close" onClick={onClick}>
      <span className="sr-only">Close Modal</span>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        role="presentation"
        aria-hidden="true"
      >
        <g fill="currentColor">
          <path d="M0.742445 2.30104C0.332404 1.891 0.332403 1.22619 0.742444 0.816153V0.816153C1.15248 0.406114 1.81729 0.406114 2.22733 0.816153L14.1064 12.6952C14.5165 13.1052 14.5165 13.7701 14.1064 14.1801V14.1801C13.6964 14.5901 13.0316 14.5901 12.6215 14.1801L0.742445 2.30104Z" />
          <path d="M12.6247 0.816662C13.0348 0.406622 13.6996 0.406621 14.1096 0.816661V0.816661C14.5197 1.2267 14.5197 1.8915 14.1096 2.30154L2.23055 14.1806C1.82051 14.5906 1.1557 14.5907 0.745661 14.1806V14.1806C0.335621 13.7706 0.335621 13.1058 0.745661 12.6957L12.6247 0.816662Z" />
        </g>
      </svg>
    </button>
  );
}

export class ModalProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stack: [],
      isModalVisible: false,
      modalProps: {},
      modalType: 'info',
    };
  }

  setModal = (modal, modalProps = {}) => {
    console.log('modalProps', modalProps)
    const modalType = modalProps.type || 'info';

    this.setState((state) => {
      const { stack } = state;

      // Check if the modal to be added is an exit modal and if an exit modal already exists in the stack
      if (modalType === 'exit' && stack.some(item => item.modalProps.type === 'exit')) {
        // console.log('Exit modal already exists in the stack');
        return state;
      }

      if (!modal) {
        const [_current, ...rest] = stack;
        // console.log('Removing modal from stack:', stack);
        return { stack: rest, isModalVisible: rest.length > 0, modalProps: rest.length > 0 ? rest[0].modalProps : {}, modalType: rest.length > 0 ? rest[0].modalProps.type : 'info' };
      }
      // console.log('Adding modal to stack:', stack);
      return { stack: [{ modal, modalProps }, ...stack], isModalVisible: true, modalProps, modalType };
    });
  };

  render() {
    const { stack, isModalVisible, modalProps } = this.state;
    const closeModal = () => this.setModal();

    const renderModalContent = () => {
      if (stack.length > 0 && typeof stack[0].modal === 'function') {
        return stack[0].modal();
      }
      console.error('The first item in the stack is not a function');
      return null;
    };

    return (
      <>
        <ModalContext.Provider
          value={{ setModal: this.setModal, isModalVisible, modalType: modalProps.type }}
        >
          {this.props.children}
        </ModalContext.Provider>

        <Modal
          isOpen={isModalVisible}
          onRequestClose={closeModal}
          {...modalProps}
        >
          {isModalVisible && renderModalContent()}
        </Modal>
      </>
    );
  }
}

export function Modal({ children, onRequestClose, showCloseButton = true, className = "", ...rest }) {
  return (
    <ReactModal onRequestClose={onRequestClose} className={className} overlayClassName={`${className}-overlay`} {...rest}>
      {showCloseButton && (
        <div className="modal-header">
          <CloseButton onClick={onRequestClose} />
        </div>
      )}
      {children}
    </ReactModal>
  );
}

export default ModalContext;

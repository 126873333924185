import React from 'react';
import './Title.css'

function Title({ text, variant = "primary", level="h1", id, ariaLevel }) {
  const Tag = level;

  const headingProps = level === 'div' 
    ? { role: 'heading', 'aria-level': ariaLevel }
    : {};

  return (
    <Tag className={`assessment-screen__title ${variant}`} id={id} {...headingProps}>
      {text}
    </Tag>
  );
}

export default Title;
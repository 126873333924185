import { useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";
import { usePrompt, skipPrompt } from '@galen/codescripts/prompt'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

import NavBar from "../components/NavBar/NavBar";
import { ClinicianCompletionToast } from '../cliniciantoast'

import success from "../img/success.png";
import successMobile from "../img/success-mobile.png";

import "./Outcome.css";

function Outcome({ state }) {
  const { assessmentId } = useParams();
  const navigate = useNavigate()
  const config = useSelector(state => state.config)

  const { assessmentNumber } = state
  const clinician = state.recordType == "clinician"

  useEffect(() => {
    if (clinician) {
        toast(<ClinicianCompletionToast completedAssessment={true}/>, {autoClose: false, closeOnClick: false, draggable: false})
    }
  }, [])

  // This function is not implemented yet
  const handleBuyInStore = () => {};

  const handleBuyOnline = () => {
    if (!!config.DISABLE_CHECKOUT_BUTTON) {
      console.log("Checkout button is disabled")
      return
    }
    
    skipPrompt()
    navigate(`/checkout?assessmentId=${assessmentId}`)
  }

  return <>
    <NavBar />
    <div id="outcome" className="outcome">
      <div className="side">
        <img className="hidden lg:block" src={success} alt="" />
        <img className="block lg:hidden" src={successMobile} alt="" />
      </div>
      <div className="container">
        <div className="p-4 pt-0 lg:p-0 space-y-4 text-center lg:text-left">
          <h1>You're ready to purchase Zena.</h1>
          <p>Are you buying in-store or online?</p>
        </div>

        <div className="space-y-4 lg:space-y-7 flex flex-col items-center">
          <button disabled={clinician} className="outcome-button" onClick={handleBuyInStore}>
            Buy in-store now
          </button>

          <button
            disabled={clinician}
            className="outcome-button"
            onClick={handleBuyOnline}
          >
            Buy Online
          </button>
        </div>

        <div className='assessment-base__assessment-number'>
          Assessment ID: <span>{ assessmentNumber }</span>
        </div>
      </div>
    </div>
  </>;
}

export default Outcome;

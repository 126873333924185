import { Link } from "react-router-dom";
import { profile } from "../../icn/index";
import BackArrow from "../../icn/back-arrow.svg";

import "./NavBar.css";

function NavBar({ onBack, showBackButton = false, showProfile = false }) {
  const handleOnClick = () => {
    onBack();
  }

  return (
    <header className="app-header" data-testid="app-header">
      <div className="header-container">
        {showBackButton && (
          <nav aria-label="back navigation">
            <ul>
              <li>
                <button className="nav-back-button" onClick={handleOnClick}>
                  <img src={BackArrow} alt="" />
                  <span className="hidden md:block">Previous Question</span>
                </button>
              </li>
            </ul>
          </nav>
        )}


          <div className="account-options">
          {showProfile && (
            <Link to="/create-account">
              <img src={profile} alt="" height="20" width="20" />
              <span className="sr-only">create account</span>
            </Link>
          )}
          </div>

      </div>
    </header>
  );
}

export default NavBar;

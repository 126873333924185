import React, { useState, useEffect } from 'react'

import './NumericInput.css'

export function validateInput(value, min, max) {
  // Ensure value is treated as a string for operations like .trim()
  const stringValue = String(value).trim();

  if (!value.length) {
    return { error: 'This field is required', hasError: true };
  }

  if (!/^\d+$/.test(value)) {
    return { error: 'Please enter a valid number', hasError: true };
  }

  if (max && Number(stringValue) > max) {
    return { error: `Please enter a number less than ${max}`, hasError: true };
  }

  if (min && stringValue && Number(stringValue) < min) {
    return { error: `Please enter a number greater than ${min}`, hasError: true };
  }

  return { error: null, hasError: false }; // No error
}

function NumericInput({
  id,
  name,
  question,
  placeholder = '',
  disabled,
  units,
  err,
  value = '',
  onEvent,
  min,
  max,
  setHasError = () => {},
}) {
  const [inputValue, setInputValue] = useState(value)
  const [error, setError] = useState(err);

  const handleOnChange = (e) => {
    const { value } = e.target;
    const { error, hasError } = validateInput(value, min, max);

    setError(error);
    setHasError(hasError);
    setInputValue(value);

    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value });
  }

  const handleOnFocus = () => onEvent({ componentId: id, type: "INPUT_SELECTED" });
  const handleOnBlur = () => onEvent({ componentId: id, type: "INPUT_DESELECTED" });

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    setError(err);
  }, [err]);

  return (
    <div className={`assessment-numeric-input`} data-disabled={disabled}>
      { question && (
        <label htmlFor={id} className="assessment-numeric-input__label">
          { question }
        </label>
      )}

      <div className={`assessment-numeric-input__input-units ${ units ? 'has-units' : '' }`}>
        <input 
          id={id}
          name={ name || id }
          className={`assessment-numeric-input__input ${units ? 'text-left' : 'text-center'}`}
          value={ inputValue }
          type="number"
          pattern="[0-9]*"
          min={ min }
          max={ max }
          placeholder={ placeholder }
          disabled={ disabled }
          aria-invalid={!!error}
          aria-label={ units || placeholder || question }
          aria-valuemin={ min }
          aria-valuemax={ max }
          aria-valuenow={ inputValue }
          onChange={handleOnChange}
          onFocus={ handleOnFocus }
          onBlur={ handleOnBlur }
          role="spinbutton"
          data-1p-ignore
        />

        { units && <span className="assessment-numeric-input__units">
            { units }
          </span>
        }
      </div>

      { error && <span className="assessment-numeric-input__error" aria-live="assertive">
          { error }
        </span>
      }
    </div>
  )
}

export default NumericInput;

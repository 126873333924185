import React from "react";

import logo from "../../img/logo-2.png";
import BackArrow from "../../icn/back-arrow.svg"

import "./NavBar.css";

function NavBar({
  onBack,
  showBackButton = false,
  showProfile = true,
}) {

  return (
    <header className="app-header">
      <div className="header-container">
          <nav aria-label="back navigation"
            aria-hidden={showBackButton ? "false" : "true"}
          >
            {/* <ul>
              <li>
                <button onClick={() => onBack()}>
                  <img src={BackArrow} alt="" />
                  <span>
                    Previous Question
                  </span>
                </button>
              </li>
            </ul> */}
          </nav>

          <img className="cs-header-logo" src={logo} alt="Logo" />
      </div>
    </header>
  )
}

export default NavBar

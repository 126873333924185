import React, { useState, useEffect } from 'react'

import './TextInput.css'

function TextInput({
  id,
  name,
  question,
  placeholder = "",
  value = "",
  err,
  disabled = false,
  ariaLabel,
  onEvent
}) {
  const [inputValue, setInputValue] = useState(value || "")
  const [error, setError] = useState(err);

  const handleOnChange = (e) => {
    setError(null);
    setInputValue(e.target.value);
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: e.target.value.trim() });
  }

  const handleOnFocus = () => onEvent({ componentId: id, type: "INPUT_SELECTED" });
  const handleOnBlur = () => onEvent({ componentId: id, type: "INPUT_DESELECTED" });

  useEffect(() => {
    setInputValue(value || "")
  }, [value])

  useEffect(() => {
    setError(err);
  }, [err]);

  return (
    <div className="assessment-text-input__container" data-disabled={disabled}>
      <label htmlFor={id} className='assessment-text-input__container' data-disabled={disabled}>
        { question }
      </label>

      <input
        id={ id }
        name={ name || id }
        className='assessment-text-input__input'
        type="text"
        value={ inputValue }
        disabled={ disabled }
        placeholder={ placeholder }
        aria-label={ ariaLabel || placeholder || question }
        aria-invalid={!!error}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
      />

      { error && <span className="assessment-numeric-input__error" aria-live="assertive">
          { error }
        </span>
      }
    </div>
  )
}

export default TextInput
import React, { Component, useLayoutEffect, useRef, useState } from 'react'

import Modal from '@galen/react-modal'

import { useAudit } from '../audit'

import Button from './AssessmentComponents/Button'
import NavBar from './NavBar/NavBar'
import MoreInfoBase from './MoreInfoBaseScreen/BaseScreen'

import { moreinfo } from '../icn/index'

import './MoreInfo.css'

export default class MoreInfo extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingInfo: false,
    }
  }

  render() {
    const { text, children } = this.props
    const { showingInfo } = this.state

    const closeInfo = () => this.setState({showingInfo: false})
    const showInfo = () => {
      this.setState({showingInfo: true})
    }

    return (
      <div>
        <button onClick={showInfo}>{ text }</button>
        <Modal isOpen={showingInfo} onRequestClose={closeInfo}>

          <div>
            <NavBar onBack={closeInfo} />
            <div>
              <MoreInfoBase
                title={"More Info"}
                showButton={true}
                buttonText={"Back"}
                onSubmit={closeInfo}
              >
                <div>
                  { children }
                </div>
              </MoreInfoBase>
            </div>
          </div>

        </Modal>
      </div>
    )
  }
}

export function Audited({children}) {
  useAudit({type: "MOREINFO_DISPLAYED"})

  return <>{ children }</>
}

export function BaseMoreInfo({ component, children, title="More Info" }) {
  const [showingInfo, setShowingInfo] = useState(false)

  const closeInfo = () => setShowingInfo(false)
  const showMoreInfo = (e) => {
    e.preventDefault()
    setShowingInfo(true)
  }

  const LinkComponent = component
  return (
    <>
      <LinkComponent showMoreInfo={showMoreInfo}/>

      <Modal 
        overlayClassName="more-info-overlay"
        className="more-info-modal" 
        isOpen={showingInfo} 
        onRequestClose={closeInfo}>
        <MoreInfoBase
          contentType="modal-info"
          id="more-info-base"
          title={title}
          showButton={true}
          buttonText={"Back"}
          onSubmit={closeInfo}
        >
          <Audited>
            { children }
          </Audited>
        </MoreInfoBase>
      </Modal>
    </>
  )
}

export function BaseModal({children, ...rest}) {
  return (
    <Modal {...rest}>
      {children}
    </Modal>
  )
}

export function MoreInfoWithPopover({ children, buttonText="More Info" }) {
  return (
    <Tooltip buttonText={buttonText}>
      {children}
    </Tooltip>
  );
}

export function MoreInfoIcon ({ children, buttonText="More Info"}) {
  return (
    <BaseMoreInfo component={({ showMoreInfo }) => {
      return (
        <button className="more-info__icon" onClick={showMoreInfo} type="button">
          <img src={moreinfo} alt="more info icon" /> { buttonText }
        </button>
      )
    }}>
      { children }
    </BaseMoreInfo>
  )
}

export function Tooltip({ children, buttonText }) {
  const [hover, setHover] = useState(true)
  const overlayNode = useRef(null)
  const ref = useRef(null)
  const componentWrapperRef = useRef(null)
  const toolTipTextRef = useRef(null)

  const handleClose = () => {
    setHover(false)
  }

  useLayoutEffect(() => {
    const handleResize = () => {
      ref.current.focus()
      // Get the coordinates of the more info icon on the screen
      const elMoreInfo = document.querySelector('.more-info__icon');
      if (elMoreInfo) {
        const rect = elMoreInfo.getBoundingClientRect();

        // Get the component wrapper element
        const componentWrapper = componentWrapperRef.current;
        if (componentWrapper) {
          componentWrapper.style.top = `${rect.top}px`;
          componentWrapper.style.left = `${rect.left}px`;
        }

        const tooltipText = toolTipTextRef.current;
        if (tooltipText) {
          tooltipText.style.top = `${rect.bottom + 24}px`;
          tooltipText.style.left = `${rect.left}px`;
        }
      }
    };

    // Run the handleResize function after a small delay
    const timeoutId = setTimeout(handleResize, 100);

    // Add the resize event listener
    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  return <>
    <div ref={ref}><MoreInfoIcon children={children} buttonText={buttonText} /></div>

    <Modal
      isOpen={hover}
      onRequestClose={handleClose}
      overlayClassName="more-info-tooltip-overlay"
      overlayRef={node => { overlayNode.current = node }}
      className='tooltip-modal-wrapper'
    >
      <div className='component-wrapper' ref={componentWrapperRef} onClick={handleClose}>
      <button className='example more-info__icon' aria-label="example more info button" tabIndex="-1">
            <img src={moreinfo} alt="" />{ buttonText || 'More info' }
          </button>
      </div>
      <div className='tooltip-text' ref={toolTipTextRef}>
        <p>On some screens you may see an information button like this:</p>

        <div className='flex jusify-center'>
          <button className='more-info__tooltip-text mx-auto my-4' aria-label="example more info button" tabIndex="-1">
            <img src={moreinfo} alt="" />{ buttonText || 'More info' }
          </button>
        </div>

        <p className='mb-4'>When you see this button, you can click it to find out additional information about the topic on that page.</p>

        <Button onClick={handleClose}>Close</Button>
      </div>
    </Modal>
  </>
}

export function MoreInfoIconPlusComponent({ children }) {
  return (
    <BaseMoreInfo
      component={({ showMoreInfo }) => {
        return <MoreInfoIcon onClick={showMoreInfo} />
      }}
    >
     { children }
    </BaseMoreInfo>
  )
}

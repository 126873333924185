import amex from "./card-amex.png"
import bloodPressure from './blood-pressure.png'
import caution from './caution.png'
import discover from "./card-discover.png"
import mastercard from "./card-mastercard.png"
import sampleQR from "./sample-qr.png"
import selectPackImg from "./select-pack-img.png"
import stop from './stop.png'
import success from "./success.png"
import visa from "./card-visa.png"
import welcome from './welcome.png'

const images = {
  "blood-pressure": bloodPressure,
  "card-visa": visa,
  "card-amex": amex,
  "card-mastercard": mastercard,
  "card-discover": discover,
  "caution": caution,
  "sample-qr": sampleQR,
  "select-pack-img": selectPackImg,
  "stop": stop,
  "success": success,
  "welcome": welcome,
}

export default images

import React, { Children } from "react"
//import { createComponent } from '../AssessmentComponents'

import AssessmentTitle from "../AssessmentComponents/Title"
import Button from "../AssessmentComponents/Button"

import './BaseScreen.css'

// custom screen styles
//import '../../css/questions/SelfOrder.css'
//import '../../css/questions/InfoEntry.css'
//import '../../css/questions/Pregnancy.css'
//import '../../css/questions/MedHistory1.css'
//import '../../css/questions/Lifestyle.css'
//import '../../css/questions/MedHistory2.css'
//import '../../css/questions/SexAtBirth.css'
//import '../../css/questions/SuicidalThoughts.css'
//import '../../css/questions/SeizureMedications.css'
//import '../../css/questions/DepressionAnxientyMedications.css'

/**
 * BaseScreen component that conditionally renders the appropriate semantic markup based on the contentType.
 * It can either render a 'form' or 'info' type screen.
 * 
 * @component
 * @param {string} id - Question identifier
 * @param {('form'|'info')} props.contentType - Type of the content to be rendered (defaults to 'form').
 * @param {string} props.beforeTitle - Component or node to be rendered before the title.
 * @param {string} props.title - The title of the screen. (h1)
 * @param {React.ReactNode} props.children - Child components to be rendered within the screen.
 * @param {boolean} props.showButton - Whether to show the 'Next' button.
 * @param {Object} props.state - State object for the screen.
 * @param {string} props.backButtonText - Text for the 'Back' button.
 * @param {boolean} props.showBackButton - Whether to show the 'Back' button.
 * @param {string} props.buttonText - Text for the 'Next' button.
 * @param {boolean} props.disableContinue - Whether to disable the 'Next' button.
 * @param {Function} props.onSubmit - Handler for the form submit event.
 * @param {Function} props.onBack - Handler for the 'Back' button click event.
 */
function MoreInfoBase({
  id,
  contentType = 'form',
  beforeTitle = null,
  title = null,
  children, 
  state={}, 
  showButton = true, 
  buttonText = 'Next', 
  showBackButton = false, 
  backButtonText = 'Back',
  disableContinue, 
  assessmentNumber = null,
  onSubmit, 
  onBack, 
}) {
  const buildTitle = () => (
    title && (
      typeof(title) == "string"
        ? <AssessmentTitle text={title} />
        : null
    )
  )


  const buildBackButton = () => (
    <Button onClick={onBack}>
      {backButtonText}
    </Button>
  )

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit()
  }

    /**
   * Function to build form content for the BaseScreen component. This includes all
   * content such as title, and buttons, nested within a form tag.
   *
   * @returns {React.ReactNode} JSX content for the form variant of BaseScreen.
   */
  const buildFormContent = () => <>
      <form className={`assessment-base form ${ id }`} onSubmit={handleSubmit}>
        <fieldset className="fieldset">
        <legend>{ title }</legend>

        { Children.toArray(children).map((child) => child) }
        </fieldset>

        {(showBackButton || showButton) && (
          <div className={`assessment-base__button-group ${ showBackButton ? 'grid-cols-2' : 'grid-cols-1'}`}>
            { showBackButton && buildBackButton() }
            { showButton && 
              <Button 
                type="submit"
                variant="full" 
                disabled={disableContinue} 
              >
                {buttonText}
              </Button>
            }
          </div>
        )}
      </form>
    </>

  /**
   * Function to build info content for the BaseScreen component. This includes all
   * content such as title, and buttons, but does not include a form.
   *
   * @returns {React.ReactNode} JSX content for the info variant of BaseScreen.
   */
  const buildInfoContent = () => (
    <div className={`assessment-base more-info ${ id }`}>
      { beforeTitle }
      { buildTitle() }

      <div className="assessment-base__more-info-content">
        {children}
      </div>

      {(showBackButton || showButton) && (
        <div className={`grid grid-cols-1`}>
          {showBackButton && buildBackButton()}
          {showButton && <Button onClick={onSubmit} disabled={disableContinue}>{buttonText}</Button>}
        </div>
      )}
    </div>
  )

  return contentType == 'form'
    ? buildFormContent()
    : buildInfoContent()
}

export default MoreInfoBase

import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import './PrivacyNotice.css'

function PrivacyNotice({ id, timestamp, onBack, onSubmit, text, children }) {
  const [expanded, setExpanded] = useState(false)

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleSubmit = () => {
    let a = {}
    if (id) {
      a = {[id]: true}
    }
    if (timestamp) {
      a = {...a, [timestamp]: new Date()}
    }
    onSubmit(a)
  }

  return (
    <div>
      <div className="assessment-privacy-notice__button-container">
        <button className="button accept-btn" onClick={handleSubmit}>
          I Accept
        </button>

        <button
          className={`privacy-drop-btn ${expanded ? 'expanded' : ''}`}
          onClick={handleToggle}
          aria-expanded={expanded}
          aria-controls="privacy-notice-full"
          id="privacy-drop-btn"
        >
            <FontAwesomeIcon icon={faChevronDown} />
            <span className="text-sm">
              { text }
            </span>
        </button>
      </div>
      
      <div aria-live="polite" aria-atomic="true">
        { expanded && (
          <div 
            className="privacy-notice" 
            id="privacy-notice-full" 
            role="region" 
            aria-labelledby="privacy-drop-btn"
          >
            { children }
          </div>
        )}
      </div>
    </div>
  )
}

export default PrivacyNotice

import React from 'react'

import './OptionSelect.css'

function OptionSelect({
  id,
  name,
  question = '',
  labelText = '',
  placeholder,
  options = [],
  value,
  disabled = false,
  onChange,
  onEvent,
}) {

  // blank option
  const placeholderOption = {
    disabled: false,
    hidden: false,
    text: placeholder,
    value: null,
  }
  options = [placeholderOption, ...options]

  const handleOnChange = (e) => {
    console.log(typeof e.target.value, e.target.value)
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: e.target.value });
  }

  return (


      <label htmlFor={ id } className="assessment-option-select__container" data-disabled={ disabled }>
        <span className="assessment-option-select__label">{ labelText }</span>

        <select
          id={ id }
          className="assessment-option-select__select"
          name={name}
          value={value}
          onChange={handleOnChange}
        >
          {options.map((opt) => (
            <option
              key={opt.value}
              className="assessment-option-select__option"
              value={opt.value}
              disabled={opt.disabled}
              hidden={opt.hidden}
            >
              {opt.text}
            </option>
          ))}
        </select>
      </label>
  )
}

export default OptionSelect
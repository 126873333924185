import React, { useState, useRef, useEffect } from 'react';

function Button({
  onClick,
  className = '',
  children,
  disabled,
  type = 'button',
  ...rest
}) {
  const { text } = rest;
  const [isDebounced, setIsDebounced] = useState(false);
  const debounceRef = useRef(null);
  const DEBOUNCE_TIME = 200;

  const handleClick = (e) => {
    if (isDebounced) return;

    setIsDebounced(true);
    if(onClick) onClick(e);

    debounceRef.current = setTimeout(() => {
      setIsDebounced(false);
    }, DEBOUNCE_TIME);
  };

  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, []);

  return (
    <button
      onClick={handleClick}
      className={`button ${className}`}
      disabled={disabled}
      type={type}
      {...rest}
    >
      {children || text}
    </button>
  );
}

export default Button;

import React from "react"
import Markdown from "react-markdown"
import edit from '../../../icn/edit.svg'

import './SummaryItem.css'

function SummaryItem({ q, handleOnEdit, editIcon }) {
  return <div className="summary__item" key={q.id}>
    <h2 className="summary__item__question">{q.question}</h2>

    <button
      className="summary__item__edit-btn"
      onClick={handleOnEdit}
      value={q.screenIndex}
    >
      { editIcon && <img className="h-6 w-6" src={edit} alt="" /> }
      <span className="sr-only">Edit {q.question}</span>
    </button>

    <div className="summary__item__answer">
      <Markdown components={{
        p: React.Fragment,
        strong: React.Fragment,
      }} unwrapDisallowed={true}>
        {q.labelText || q.value}
      </Markdown>
    </div>
  </div>
}

export default SummaryItem

import { 
  DefaultAssessmentService, 
  DefaultAuthService, 
  DefaultCheckoutService, 
  DefaultAccountService 
} from './service'



export function Mutex() {
  let locked = false
  let waiting = []

  function lock() {
    if (!locked) {
      locked = true
      return
    }

    let unlocked
    const p = new Promise(r => unlocked = r)
    waiting.push(unlocked)

    return p
  }

  function unlock() {
    if (!waiting.length) {
      locked = false
      return
    }

    const next = waiting.pop()
    next(1)
  }

  return {
    lock,
    unlock,
  }
}

export function getSearchParams() {
  const ret = {}
  for (const [k, v] of window.location.search
    .slice(1)
    .split("&")
    .map((x) => x.split("="))) {
    ret[k] = v
  }
  return ret
}

function AuthClient(tok) {
  const mu = Mutex()
  let _token = tok
  let _id = Math.random()
  let _accountService

  async function _getToken() {
    const { invite, clinician } = getSearchParams()

    const res = await fetch("/auth/token", {
      method: "POST",
      body: JSON.stringify({
        invite,
        clinician,
      }),
    })

    if (!res.ok) {
      throw res
    }

    const token = await res.json()
    return token
  }

  async function _setToken(token) {
    sessionStorage.setItem("token", JSON.stringify(token))
    _token = token
  }

  async function setToken(token) {
    await mu.lock()
    _setToken(token)
    mu.unlock()
  }

  async function getToken() {
    await mu.lock()
    try {
      if (!_token) {
        const t = await _getToken()
        _setToken(t)
      }
    } catch (e) {
      throw e
    }finally {
      mu.unlock()
    }
    return _token.token
  }

  async function fetchWithToken(req) {
    const token = await getToken()
    if (token) {
      req.headers.append("authorization", token)
    }
    return fetch(req)
  }

  async function guestLogin() {
    const token = await _getToken()
    _setToken(token)
    sessionStorage.removeItem("authed")

    //store.dispatch({ type: "GUEST_LOGIN_SUCCESS", token })
  }

  async function appleLogin(authorization, mode, transfer) {
    const tok = await getToken()

    const opts = {
      method: "POST",
      headers: {
        authorization: tok,
      },
      body: JSON.stringify({
        mode,
        transfer,
        origin: window.location.origin,
        ...authorization,
      }),
    }

    const res = await fetch("/auth/apple/success", opts)
    const { status, token, history } = await res.json()
    _setToken(token)

    return { status, token, history }
  }


  async function googleLogin(code, mode, transfer) {
    const tok = await getToken()

    const opts = {
      method: "POST",
      headers: {
        authorization: tok,
      },
      body: JSON.stringify({
        origin: window.location.origin,
        code,
        mode,
        transfer,
      }),
    }

    const res = await fetch("/auth/google/oauth", opts)
    const { status, token, history } = await res.json()
    _setToken(token)

    return { status, token, history }
  }

  async function getHistory() {
    const req = new Request("/auth/history", { method: "GET" })
    const res = await fetchWithToken(req)
    const { history } = await res.json()
    return history
  }

  function getAccountService() {
    if (!_accountService) {
      _accountService = new DefaultAccountService('', fetchWithToken)
    }

    return _accountService
  }

  async function createAccount({email, password}) {
    const accountService = getAccountService()
    const { token: rawToken, history } = await accountService.createAccount({email, password})
    const token = JSON.parse(rawToken)
    _setToken(token)

    return {
      token,
      history: JSON.parse(history),
    }
  }

  async function emailLogin({email, password}) {
    const accountService = getAccountService()
    const { token: rawToken, history } = await accountService.emailLogin({email, password})
    const token = JSON.parse(rawToken)
    _setToken(token)

    return {
      token,
      history: JSON.parse(history),
    }
  }

  return {
    getToken,
    setToken,
    getHistory,
    fetchWithToken,
    appleLogin,
    googleLogin,
    guestLogin,
    createAccount,
    emailLogin,
  }
}
// const fetchWithAuth = (req) => {
//   const { auth } = store.getState()
//   if (auth.token) {
//     req.headers.append("authorization", auth.token.token)
//   }
//   return fetch(req)
// }

const existingToken = sessionStorage.getItem("token") && JSON.parse(sessionStorage.getItem("token"))

export const authClient = AuthClient(existingToken)
export const client = new DefaultAssessmentService('', authClient.fetchWithToken)
export const checkoutClient = new DefaultCheckoutService('', authClient.fetchWithToken)

//export const authClient = new DefaultAuthService('', fetchWithAuth)

import React, { useEffect, useState } from 'react';

import './BPNumbers.css';

// regex to check if id contains 'systolic' or 'diastolic'
// for example finalDiastolic will return 'diastolic'
// returnSystolic will return 'systolic'
// id can be lower or upper case first letter
const systolicRegex = /systolic/i;
const diastolicRegex = /diastolic/i;

const mapComponentId = (id) => {
  if (id.match(systolicRegex)) {
    return 'systolic';
  } else if (id.match(diastolicRegex)) {
    return 'diastolic'
  }
  return 'invalid id';
}

function BPNumbers({ id = "systolic", value, onEvent, hideErrs, err }) {
  const [errors, setErrors] = useState("");
  const [hasErrors, setHasErrors] = useState(false);
  const [inputValue, setInputValue] = useState("")


  const handleOnChange = (e) => {
    const { name } = e.target;
    // Remove any spaces from the input value also
    // remove [a-zA-Z] characters from the input value
    const value = e.target.value.replace(/[^0-9]/g, '');

    // const newErrors = validateBP(value, name);
    setInputValue(value);
    // setErrors(current => ({ ...current, [name]: newErrors }));

    // Automatically focus on diastolic input when systolic input has 3 digits
    if (name === 'systolic' && value.length === 3) {
      // [0]: systolic, [1]: diastolic
      const bpInputs = document.querySelectorAll('.assessment-bp-numbers input');
      if (bpInputs[1]) {
        bpInputs[1].focus();
      }
    }
  };

  // Use useEffect to submit the form when both values are present
  useEffect(() => {
      onEvent({
        componentId: id,
        type: "UPDATED_ANSWER",
        newValue: inputValue
      });
  }, [inputValue]);

  useEffect(() => {
    setHasErrors(err ? true : false);
    if(err) {
      setErrors(err);
    }
  }, [err]);

  useEffect(() => {
    if (value) setInputValue(value)
  }, [value]);

  return <>
    <div className="assessment-bp-numbers">
      <label htmlFor={id} className={ hasErrors > 0 ? 'has-error' : null }>
        <div className='input-label'>
          <span>{mapComponentId(id) === 'systolic' ? 'Systolic' : 'Diastolic'}</span>
          <span>({ mapComponentId(id) === 'systolic' ? 'top number' : 'bottom number' })</span>
        </div>

        <div className='flex flex-col items-end'>
          <input 
            type="text"
            name={mapComponentId(id)}
            id={id}
            maxLength={3}
            value={inputValue}
            onChange={handleOnChange}
            onFocus={hideErrs}
            aria-invalid={hasErrors}
            autoComplete='off'
            autoCorrect='off'
            data-1p-ignore
          />


            { hasErrors &&
              <div className='max-w-[120px] mt-2 italic text-sm text-red-600' aria-live="assertive">
                { errors }
              </div>
            }
        </div>
      </label>
    </div>
  </>;
}

export default BPNumbers;

import React, { useEffect, useState } from "react";

import "./HeightInput.css";

// helper function to calculate feet and inches to inches
export function calculateHeightToInches(feet, inches) {
  return parseInt(feet) * 12 + parseInt(inches);
}

// helper function to calculate inches to feet and inches
export function calculateInchesToHeight(inches) {
  const feet = Math.floor(inches / 12);
  const remainingInches = inches % 12;
  return { feet: feet, inches: remainingInches };
}

const HeightInput = ({ id, value, onEvent, labelText = "Enter your height" }) => {
  const [height, setHeight] = useState({ feet: "", inches: "" });

  const handleOnChange = (e) => {
    const { value } = e.target;

    if (!value) {
      setHeight({ feet: '', inches: '' });
      return;
    }
    
    const [feet, inchesPart] = value.split('ft ');
    const inches = inchesPart.split('in')[0];
  
    setHeight((current) => ({ ...current, feet: feet.trim(), inches: inches.trim() }));
  };

  useEffect(() => {
    if (value) {
      const { feet, inches } = calculateInchesToHeight(value);
      setHeight({ feet, inches });
    }
  }, [value]);

  useEffect(() => {
    const newValue =
      (height.feet != null && height.inches != null)
        ? `${calculateHeightToInches(height.feet, height.inches)}`
        : null;

    onEvent({
      componentId: id,
      type: "UPDATED_ANSWER",
      newValue: newValue,
    });
  }, [height]);

  return <div className="assessment-height">
    <label aria-hidden="true">{labelText}</label>
    <div className="input-group">
      <label htmlFor={`${id}-height`} className="sr-only">
        Height
      </label>
      <select
        className="height"
        name="height"
        id={`${id}-height`}
        onChange={handleOnChange}
        value={`${height.feet}ft ${height.inches}in`}
        aria-label="Select your height in feet and inches"
        role="combobox"
      >
        <option value=""></option>
        {[...Array(3).keys()].flatMap((i) =>
          [...Array(12).keys()].map((j) => {
            const feet = i + 4;
            const inches = j;
            // Skip options before 4 feet 10 inches and after 6 feet 9 inches
            if ((feet === 4 && inches < 10) || (feet === 6 && inches > 9)) return null;

            return (
              <option key={`${feet}-${inches}`} value={`${feet}ft ${inches}in`}>
                {feet} feet, {inches} inches
              </option>
            );
          })
        )}
      </select>
    </div>
  </div>;
};

export default HeightInput;

// const HeightInput = ({ id, value, onEvent, labelText = "Enter your height" }) => {
//   const [height, setHeight] = useState({ feet: "", inches: "" });

//   const handleOnChange = (e) => {
//     const { name, value } = e.target;
//     setHeight((current) => ({ ...current, [name]: value }));
//   };

//   useEffect(() => {
//     if (value) {
//       const { feet, inches } = calculateInchesToHeight(value);
//       setHeight({ feet: feet, inches: inches });
//     }
//   }, [value]);

//   useEffect(() => {
//     const newValue =
//       height.feet && height.inches
//         ? `${calculateHeightToInches(height.feet, height.inches)}` // cast to string
//         : null;

//     onEvent({
//       componentId: id,
//       type: "UPDATED_ANSWER",
//       newValue: newValue,
//     });
//   }, [height.feet, height.inches]);

//   return (
//     <>
//       <div className="assessment-height">
//         <label aria-hidden="true">{labelText}</label>
//         <div className="input-group">
//           <label htmlFor={`${id}-feet`} className="sr-only">
//             Feet
//           </label>
//           <select
//             className="feet"
//             name="feet"
//             id={`${id}-feet`}
//             onChange={handleOnChange}
//             value={height.feet}
//             aria-label="enter your height. select feet"
//           >
//             <option value="">Feet</option>
//             {[...Array(3).keys()].map((i) => (
//               <option key={i} value={i + 4}>
//                 {i + 4}
//               </option>
//             ))}
//           </select>

//           <label htmlFor={`${id}-inches`} className="sr-only">
//             Inches
//           </label>
//           <select
//             className="inches"
//             name="inches"
//             id={`${id}-inches`}
//             onChange={handleOnChange}
//             value={height.inches}
//             aria-label="enter your height. select inches"
//           >
//             <option value="">Inches</option>
//             {[...Array(12).keys()].map((i) => (
//               <option key={i} value={i}>
//                 {i}
//               </option>
//             ))}
//           </select>
//         </div>
//       </div>
//     </>
//   );
// };
import React, { useState, useEffect, useRef } from 'react'

import './Date.css'

export function isValidDate(d, m, y) {
  d = parseInt(d, 10);
  m = parseInt(m, 10) - 1;
  y = parseInt(y, 10);

  if (isNaN(d) || isNaN(m) || isNaN(y)) {
    console.warn('One of the inputs is not a number.');
    return;
  }

  var date = new Date(y, m, d);

  return date && (date.getDate() === d) && (date.getMonth() === m) && (date.getFullYear() === y);
}

function DateInput({
  id,
  fieldsetVariant = '',
  question,
  value,
  err,
  onEvent = () => {}
}) {
  const dayRef = useRef(null);
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  const [month, setMonth] = useState("")
  const [day, setDay] = useState("")
  const [year, setYear] = useState("")
  const [navBack, setNavBack] = useState(false)

  const [monthError, setMonthError] = useState(false)
  const [dayError, setDayError] = useState(false)
  const [yearError, setYearError] = useState(false)

  const handleKeyUp = (e) => {
    // Check if the key is an arrow key and return immediately if it is
    if(e.keyCode === 37 || e.keyCode === 39) {
      return;
    }
    
    // Check if the backspace key was pressed and handle it
    if(e.keyCode === 8) {
      handleBackspace(e);
      return;
    } else {
      setNavBack(false);
    }
  
    // Check if the max length of the input field was reached and handle it
    if(e.target.value.length >= e.target.maxLength) {
      handleMaxLength(e);
      return;
    }
  }

  const handleBackspace = (e) => {
    if (e.target.value === "" && !navBack) {
      setNavBack(true);
      return;
    }
    
    if (e.target.value === "" && navBack) {
      setNavBack(false);
      switch (e.target.name) {
        case "day": 
          monthRef.current.focus();
          setMonth(month.slice(0, -1));
          break;
        case "year": 
          dayRef.current.focus(); 
          setDay(day.slice(0, -1));
          break;
      }
    }
  }

  const handleMaxLength = (e) => {
    if(e.keyCode === 9 || e.keyCode === 16) return;
  
    switch(e.target.name) {
      case "month": 
        dayRef.current.focus(); 
        break;
      case "day": 
        yearRef.current.focus(); 
        break;
    }
  }

  const handleBlur = (e) => {
    switch (e.target.name) {
      case "month": 
        if (e.target.value.length < 2) {
          setMonthError("Enter the month as a 2 digit number.");
        } else {
          setMonthError("");
        }
        break;
      case "day": 
        if (e.target.value.length < 2) {
          setDayError("Enter the day as a 2 digit number.");
        } else {
          setDayError("");
        }
        break;
      case "year": 
        if (e.target.value.length < 4) {
          setYearError("Enter the year as a 4 digit number.");
        } else {
          setYearError("");
        }
        break;
    }
  }

  const handleChange = (e) => {
    switch(e.target.name) {
      case "month": 
        const newMonth = e.target.value;
        if (newMonth.length <= 2) {
          setMonth(e.target.value);
        }

        if (parseInt(newMonth) > 12) {
          setMonthError("That month isn't valid. Check the month and day aren't reversed.");
        } else {
          setMonthError("");
        }
        break;
      case "day": 
        const newDay = e.target.value;
        if (e.target.value.length <= 2) {
          setDay(e.target.value);
        }

        if (parseInt(newDay) > 31) {
          setDayError("Day must be between 1 and 31.");
        } else {
          setDayError("");
        }
        break;
      case "year": 
        if (e.target.value.length <= 4) {
          setYear(e.target.value);
        }

        if (e.target.value.length === 4) {
          setYearError("");
        }
        break;
    }
  }

  useEffect(() => {
    if(value) {
      const [y, m, d] = value.split('-');

      setMonth(m);
      setDay(d);
      setYear(y);
    }
  }, [value]);

  useEffect(() => {
    if (year.length >= 4 && isValidDate(day, month, year)) {
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: `${year}-${month}-${day}` })
    } else {
      onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: null })
    }
  }, [month, day, year]);

  return <>
    { question && <p>{ question }</p>}
    <div className={ `assessment-date-input__container ${fieldsetVariant}` }>
      <div className="date-input-group" onKeyUp={handleKeyUp} aria-labelledby="dateLabel">
        <input 
          type="number" 
          name="month"
          ref={monthRef}
          value={month}
          placeholder="MM"
          min="1"
          max="12"
          maxLength="2"
          aria-label='Month'
          aria-describedby={monthError ? "monthError" : null}
          onBlur={ (e) => handleBlur(e) }
          onChange={ (e) => handleChange(e) }
          data-1p-ignore
        /> 
        /
        <input 
          type="number" 
          name="day"
          ref={dayRef}
          value={day}
          placeholder="DD"
          maxLength="2"
          aria-label='Day'
          aria-describedby={dayError ? "dayError" : null}
          onBlur={ (e) => handleBlur(e) }
          onChange={ (e) => handleChange(e) }
          data-1p-ignore
        />
        /
        <input 
          type="number" 
          name="year"
          ref={yearRef}
          value={year}
          placeholder="YYYY"
          min="1901"
          max="2023"
          maxLength="4"
          aria-label='Year'
          aria-describedby={yearError ? "yearError" : null}
          onBlur={ (e) => handleBlur(e) }
          onChange={ (e) => handleChange(e) }
          data-1p-ignore
        />
      </div>

    {(monthError || dayError || yearError || err) && (
      <ul className="date-error-group" aria-live="assertive" role="alert">
        { monthError && <li id="monthError">{monthError}</li> }
        { dayError && <li id="dayError">{dayError}</li> }
        { yearError && <li id="yearError">{yearError}</li> }
        { err && <li>{err}</li> }
      </ul>
    )}
  </div>
</>}

export default DateInput
import React from 'react';

/**
 * Renders a hyperlink (`<a>` element) with specified text and href.
 * Optionally, a `target` attribute can be specified to control where the linked document is opened.
 * 
 * For security reasons, when `target="_blank"` is used (opening the link in a new tab or window),
 * `rel="noopener noreferrer"` is automatically added to the `<a>` element. This prevents the new page
 * from accessing the `window.opener` property and ensures it runs in a separate process,
 * mitigating potential security risks like tabnabbing. It also prevents the `Referer` header
 * from being sent to the new page.
 * 
 * DO NOT CONFUSE THIS WITH REACT ROUTER'S `Link` COMPONENT. 
 * This component is for external links only.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.text - The text to display for the link.
 * @param {string} props.href - The URL that the link points to.
 * @param {string} [props.target] - Optional. Specifies where to open the linked document.
 *                                 Values include "_blank" (new window or tab), "_self" (same frame as clicked),
 *                                 "_parent" (parent frame), or "_top" (full body of the window).
 * @returns {React.Element} A React Element representing the hyperlink.
 */
function Link({ text, href, target }) {
  const relValue = target === '_blank' ? 'noopener noreferrer' : undefined;

  return (
    <a href={href} target={target} rel={relValue}>
      {text}
    </a>
  );
}

export default Link;
import React, { useState, useEffect, useRef } from "react";
import './YesNo.css';

function YesNo({
  id,
  question = "", 
  onEvent, 
  onSubmit,
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const yesButtonRef = useRef(null);
  const noButtonRef = useRef(null);
  const options = [
    { value: "yes", label: "Yes", ref: yesButtonRef },
    { value: "no", label: "No", ref: noButtonRef },
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDisabled(false);
    }, 500); // 500 milliseconds = 0.5 seconds

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowRight" || event.key === "ArrowLeft") {
        event.preventDefault();
        const currentRef = (document.activeElement === yesButtonRef.current) ? noButtonRef : yesButtonRef;
        currentRef.current.focus();
      }
    };

    const container = document.querySelector('.assessment_yesno__container');
    container.addEventListener('keydown', handleKeyDown);

    return () => {
      container.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleClick = (e) => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: e.target.value });
    onSubmit({ [id]: e.target.value });
  };

  return (
    <div className="assessment_yesno__container" aria-labelledby={id} role="group" tabIndex="-1">
      {options.map(({ value, label, ref }) => (
        <button 
          value={value}
          className={`button yesno ${value}`}
          key={`assessment-yesno-${label}`}
          onClick={(e) => handleClick(e)}
          aria-label={`Select ${label} for ${question}`}
          disabled={isDisabled}
          ref={ref}
        >
          {label}
        </button>
      ))}
    </div>
  );
}

export default YesNo;

import React, { useState } from "react";
import Checkbox from "./Checkbox";

import './AdbuConfirm.css';



function AdbuNoConfirm({ onClose, onConfirm, useAudit }) {
  const [checked, setChecked] = useState(false);
  const onEvent = useAudit({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED" });

  return (
    <div className="adbu-confirm" id="attestationConfirmed">
      <p>
        By clicking the checkmark box, I confirm that I will need to <b>ask a doctor if Zena</b> is safe for me based on my information.
      </p>


      <Checkbox checked={checked} value="confirm" onCheck={setChecked} />

      <button
        className="button secondary"
        onClick={async () => {
          await onEvent({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_GO_BACK" });
          onClose();
        }}
      >
        Go Back
      </button>
      <button
        className="button button-primary"
        disabled={!checked}
        onClick={async () => {
          await onEvent({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_CONFIRMED" });
          onConfirm({ attestationNoConfirmed: 'yes' })
        }}
      >
        Confirm
      </button>
    </div>
  );
}

export default AdbuNoConfirm;

import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const useConfig = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await fetch("/config/config.json");
        const config = await response.json();
        dispatch({ type: "FETCH_CONFIG_SUCCESS", config });
      } catch (error) {
        console.error('Fetching configuration failed:', error);
        dispatch({ type: "FETCH_CONFIG_FAILURE", error });
      }
    };

    fetchConfig();
  }, [dispatch]);
};

export default useConfig;
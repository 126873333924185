import React, { useState } from "react";

import Checkbox from "./Checkbox";

import './AdbuConfirm.css';

function AdbuYesConfirm({ onClose, onConfirm, useAudit }) {
  const [checked, setChecked] = useState(false);
  const onEvent = useAudit({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_DISPLAYED" });

  return (
    <div className="adbu-confirm" id="attestationConfirmed">
      <p>
        By clicking the checkmark box, I confirm that <strong>I have talked to a doctor who confirmed</strong> that Zena is safe for me based on the information I provided.
      </p>


      <Checkbox checked={checked} value="confirm" onCheck={setChecked} />

      <button
        className="button secondary"
        onClick={async () => {
          await onEvent({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_GO_BACK" });
          onClose();
        }}
      >
        Go Back
      </button>
      <button
        className="button button-primary"
        disabled={!checked}
        onClick={async () => {
          await onEvent({ type: "OUTCOME_ATTESTATION_CONFIRM_MODAL_CONFIRMED" });
          onConfirm({ attestationConfirmed: 'yes' })
        }}
      >
        Confirm
      </button>
    </div>
  );
}

export default AdbuYesConfirm;
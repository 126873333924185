import { useState, useEffect, useContext } from 'react'
import Markdown from "react-markdown";

import ModalContext from '../modal'
import { createComponent, components } from './AssessmentComponents'
import { questions, validate } from '../spec/utils'

import { useAssessment } from '../context/assessmentContext';
import { useAudit } from '../audit'
import ConfirmButton from "./ConfirmButton/ConfirmButton"

// this modal prompts the user to check their answer, does not progress the assessment
const CheckAnswer = ({ children = [], onClose, answers }) => {
  const handleOnClick = (e) => {
    onClose()
  }

  // iterate over children 
  // any instance of {{ property }} will be 
  // replaced with answer[property]
  children = children.map(c => {
    let newC = c
    const matches = c.match(/{{\s*([a-zA-Z0-9]+)\s*}}/g)

    if (matches) {
      matches.forEach(m => {
        const key = m.replace(/{{\s*|\s*}}/g, '')
        newC = newC.replace(m, answers[key])
      })
    }

    return newC
  })


  return <div className="confirm-answers-modal check-answer">
    <div className='confirm-answers-modal-content'>
      { children.map((t, i) => <Markdown children={t} key={i} />) }
    </div>
    <div className="check-modal-btn-group">
      <button onClick={handleOnClick}>Change</button>
    </div>
  </div>

}

// This Modal prompts the user to confirm their answer or go back to change it
const GoBackOrConfirmModal = ({ id, children = [], onEvent, onConfirm, onClose }) => {
  const handleOnClick = (e) => {
    const { value } = e.currentTarget
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value })
    onConfirm({ [id]: value })
    onClose()
  }

  return <div className="confirm-answers-modal goBack-confirm">
    <div className="confirm-answers-modal-content">
      { children.map((t, i) => <Markdown children={t} key={i} />) }
    </div>
    <div className="confirm-modal-btn-group">
      <button className="go-back-button" onClick={() => onClose()} value="no">Go Back</button>
      <button className="confirm-modal-button" onClick={handleOnClick} value="yes">Confirm</button>
    </div>
  </div>
}

// this modal prompts the user to confirm their answer, progresses the assessment
const YesNoConfirm = ({ id, children = [], onEvent, onConfirm, onClose, onBack, canGoBack }) => {
  const handleOnClick = (e) => {
    const { value } = e.currentTarget

    // gross
    if (canGoBack && value === 'no') {
      onBack()
      onClose()
      return
    }


    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value })
    onConfirm({ [id]: value })
    onClose()
  }

  return <div className="confirm-answers-modal yesno-confirm">
    <div className='confirm-answers-modal-content'>
      { children.map((t, i) => <Markdown children={t} key={i} />) }
    </div>
    <div className="confirm-modal-btn-group">
      <button onClick={handleOnClick} value="yes">Yes</button>
      <button onClick={handleOnClick} value="no">No</button>
    </div>
  </div>
}

const FinalYesNoConfirm = ({ id, children = [], onEvent, onConfirm, onClose, onBack, canGoBack }) => {
  const handleOnClick = (e) => {
    const { value } = e.currentTarget

    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: value })
    onConfirm({ [id]: value })
    onClose()
  }

  return <div className="confirm-answers-modal yesno-confirm">
    <div className='confirm-answers-modal-content'>
      { children.map((t, i) => <Markdown children={t} key={i} />) }
    </div>
    <div className="confirm-modal-btn-group">
      <button onClick={handleOnClick} value="yes">Yes</button>
      <button onClick={handleOnClick} value="no">No</button>
    </div>
  </div>
}

const ConfirmModal = ({ id, onClose, onEvent, onConfirm, children = [] }) => {
  const handleOnClick = () => {
    onEvent({ componentId: id, type: "UPDATED_ANSWER", newValue: 'yes' })
    onConfirm({ [id]: 'yes' })
    onClose()
  }

  return (
    <div className="confirm-modal">
      <div className="confirm-answers">
        <ConfirmButton onConfirm={handleOnClick} aria-labelledby="confirm-button-label" />
        <span id="confirm-button-label">
          { children }
        </span>
      </div>
    </div>
  )
}


const confirmModals = {
  checkAnswer: CheckAnswer,
  yesnoConfirm: YesNoConfirm,
  FinalYesNoConfirm,
  goBackOrConfirmModal: GoBackOrConfirmModal,
  ConfirmModal: ConfirmModal,
}

export default function Assessment({ showBackButton, assessmentComponents, images }) {
  const { 
    spec: rawSpec,
    state: rawState,
    onSubmit, 
    onEvent, 
    onBack 
  } = useAssessment()
  const [auditTrail, setAuditTrail] = useState([]) // trail of events
  const { setModal } = useContext(ModalContext)
  const allComponents = {...components, ...assessmentComponents}

  let state = rawState
  let spec = rawSpec
  if (state.state == "completed") {
    spec = {
      ...spec,
      children: spec.post,
    }

    state = {
      ...state,
      index: state.postIndex,
    }
  }

  const initScreenState = () => {
    const screenState = {answers: {}}
    questions(spec.children[state.index]).forEach(q => {
      if (state.answers[q.id] !== undefined) {
        screenState.answers[q.id] = state.answers[q.id]
      }
    })
    return screenState
  }

  const [screenState, setScreenState] = useState(initScreenState())
  const [shouldShowErrs, setShouldShowErrs] = useState(false)

  const screenErrs = validate(spec.children[state.index], screenState.answers)

  useEffect(() => {
    setScreenState(initScreenState())
  }, [rawState])

  const handleEvent = e => {
    if (e.type == 'UPDATED_ANSWER') {
      setScreenState(s => ({answers: {...s.answers, [e.componentId]: e.newValue}}))
    }
    if (e.type == 'UPDATED_ANSWERS') {
      setScreenState(s => ({answers: {...s.answers, ...e.answers}}))
    }
    setAuditTrail(t => [...t, e])
    onEvent(e)
  }

  const handleSubmit = a => {
    // yes/no buttons submit their answers at the same time the screen is submitted
    const answers = { ...screenState.answers, ...a}
    const screenErrs = validate(spec.children[state.index], answers)
    const errs = Object.keys(screenErrs).length > 0
    if (errs) {
      console.error(screenErrs)
      if (!shouldShowErrs) {
        setShouldShowErrs(true)
      }
      return
    }

    if (shouldShowErrs) {
      setShouldShowErrs(false)
    }

    onSubmit({answers})
  }

  const hideErrs = () => {
    setShouldShowErrs(false)
  }

  const { attestation } = rawSpec
  const screenSpec = spec.children[state.index]
  const getValue = qid => screenState.answers[qid]
  const answers = {...state.answers, ...screenState.answers}

  const confirmBeforeSubmit = a => {
    const allAnswers = {...answers, ...a}
    const errs = validate(screenSpec, allAnswers)
    if (Object.keys(errs).length > 0) {
      return handleSubmit(a) // prevents modal from showing if validation errors
    }

    for (let i = 0; i < screenSpec.confirm.length; i++) {
      const c = screenSpec.confirm[i]
      const fn = new Function(`return state => ${c.cond}`)()

      if (fn(allAnswers)) {
        // not actually sure what is going on here with c.event
        // but keeping it for all the other confirm modals.
        // Does not seem to apply to ConfirmModal cases...
        const componentId = c.type == 'ConfirmModal' ? c.id : c.event
        const handleConfirm = (v) => {
          setModal()
          onEvent({type: "CONFIRM", componentId: componentId })
          let payload = {...a, ...v}
          if (c.timestamp) {
            payload = {...payload, [c.timestamp]: new Date()}
          }

          handleSubmit(payload)
        }

        const closeModal = () => setModal()

        const Component = confirmModals[c.type] || assessmentComponents[c.type]
        const modalProps = {
          id: c.id,
          children: c.text, // array of markdown strings
          answers: answers,
          canGoBack: c.canGoBack,
          onBack: onBack,
          onConfirm: handleConfirm,
          onClose: closeModal,
          onEvent: onEvent,
          getValue: getValue,
          useAudit: useAudit,
        }
        const createModal = () => <Component {...modalProps} />

        setModal(createModal, { className: 'confirm-modal', type: 'confirm' })
        return
      }
    }

    handleSubmit(a)
  }

  const handleConfirmSubmit = screenSpec.confirm ? confirmBeforeSubmit : handleSubmit

  const props = {
    global: spec.global,
    isLoading: false,
    onSubmit: handleConfirmSubmit,
    onEvent: handleEvent,
    getValue,
    answers,
    shouldShowErrs,
    screenErrs,
    hideErrs,
    images,
    screenSpec,
    onBack,
    showBackButton,
    state,
    attestation,
    progress: (state.index / spec.children.length) * 100,
  }

  return createComponent(screenSpec, props, allComponents)
}


import React, { createElement } from "react"
import PrivacyNotice from "./PrivacyNotice/PrivacyNotice"

// Screens
import Screen from "./Screen/Screen"
import KickoutScreen from "./KickoutScreen/KickoutScreen"

// Assessment Components
import Accordion from "./Accordion/Accordion"
import Banner from "./AssessmentComponents/Banner"
import Button from "./AssessmentComponents/Button"
import Checkbox from "./AssessmentComponents/Checkbox"
import CheckboxGroup from "./AssessmentComponents/CheckboxGroup"
import DateInput from "./AssessmentComponents/DateInput"
import DatePicker from "./AssessmentComponents/DatePicker"
import Div from "./AssessmentComponents/Div"
import HeightSelect from "./AssessmentComponents/HeightSelect"
import Image from "./AssessmentComponents/Image"
import Link from "./AssessmentComponents/Link"
import List from "./AssessmentComponents/List"
import MarkdownComponent from "./AssessmentComponents/Markdown"
import MoreInfo, { MoreInfoIcon, MoreInfoIconPlusComponent, MoreInfoWithPopover } from "./MoreInfo"
import OptionSelect from "./AssessmentComponents/OptionSelect"
import NumericInput from "./AssessmentComponents/NumericInput"
import Paragraph from "./AssessmentComponents/Paragraph"
import Radio from "./AssessmentComponents/Radio"
import RadioGroup from "./AssessmentComponents/RadioGroup"
import SignInComponent from "./AssessmentComponents/SignIn"
import Spacer from "./AssessmentComponents/Spacer"
import Span from "./AssessmentComponents/Span"
import Table from "./AssessmentComponents/Table"
import TextInput from "./AssessmentComponents/TextInput"
import Title from "./AssessmentComponents/Title"
import YesNo from "./AssessmentComponents/YesNo"
import ConfirmButtons from "./AssessmentComponents/ConfirmButtons"
import { ConfirmModal } from "./AssessmentComponents/ConfirmButton"
import { Audit } from "../audit"

export function virtual() { return <></> }

export const components = {
  Accordion: Accordion,
  Audit,
  Banner: Banner,
  Button: Button,
  Checkbox: Checkbox,
  CheckboxGroup: CheckboxGroup,
  DateInput: DateInput,
  DatePicker: DatePicker,
  Div: Div,
  HeightSelect: HeightSelect,
  Image: Image,
  KickoutScreen: KickoutScreen,
  Link: Link,
  List: List,
  Markdown: MarkdownComponent,
  MoreInfo: MoreInfo,
  MoreInfoIcon: MoreInfoIcon,
  MoreInfoIconPlusComponent: MoreInfoIconPlusComponent,
  MoreInfoWithPopover: MoreInfoWithPopover,
  NumericInput: NumericInput,
  OptionSelect: OptionSelect,
  Paragraph: Paragraph,
  PrivacyNotice: PrivacyNotice,
  Radio: Radio,
  RadioGroup: RadioGroup,
  Screen: Screen,
  SignInComponent: SignInComponent,
  Spacer: Spacer,
  Span: Span,
  Table: Table,
  TextInput: TextInput,
  Title: Title,
  YesNoButtons: YesNo,
  ConfirmButtons,
  ConfirmModal,
  virtual,
};

export function registerComponent(name, def) {
  components[name] = def
}

export function registerCustomComponent(name, def) {
  const fn = (props) => {
    return createComponent(def, props)
  }

  fn.displayName = name
  components[name] = fn
}

export function createComponent(componentspec, props = {}, assessmentComponents = components) {
  // create read only component

  // don't pass key through the tree, or else we get children w/ dupe keys
  //const { key, ...rest } = props
  const rest = Object.assign({}, props, { key: undefined })

  var renderedchildren = []
  if (componentspec.children) {
    renderedchildren = componentspec.children.map((c) =>
      createComponent(c, rest, assessmentComponents)
    )
  }

  const component = assessmentComponents[componentspec.type]
  if (component === undefined) {
    console.error("component missing", componentspec.type)
    return null
  }

  const { children, ...specProps } = componentspec
  const mergedProps = {
    ...specProps,
    ...props,
  }

  if (componentspec.id) {
    //FIXME:
    mergedProps.value = props.getValue && props.getValue(componentspec.id)
    mergedProps.key = componentspec.id

    const err = props.screenErrs?.[componentspec.id]
    if (err && props.shouldShowErrs) {
      mergedProps.err = err
    }
  }

  return createElement(component, mergedProps, ...renderedchildren)
}


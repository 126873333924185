import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";


import './Accordion.css'

function Accordion({ text, children }) {
  const [expanded, setExpanded] = useState(false)
  return (
    <div className="accordion">
      <button
        className={`accordion-drop-btn ${expanded ? 'expanded' : ''}`}
        onClick={() => setExpanded(!expanded)}
        aria-expanded={expanded}
        aria-controls="accordion-content"
      >
          <FontAwesomeIcon icon={faChevronDown} />
          <span className="text-sm">
            { text }
          </span>
      </button>
 
      { expanded && (
        <div className="accordion-content" id="accordion-content" role="region" aria-expanded={expanded}>
          { children }
        </div>
      )}
    </div>
  )
}

export default Accordion
function Checkbox({ id, checked, value, onCheck, children = 'I have read and understand'}) { 
  return (
    <label 
      htmlFor="confirm" 
      className={`confirm-checkbox__container`}>
      <input
        id="confirm" 
        className='checkbox__input'
        value={value}
        type="checkbox"
        checked={checked}
        aria-checked={checked}
        aria-labelledby="acknowledgement"
        onChange={(e) => onCheck(e.target.checked)}
      />
      <span className="styled-checkbox" role="presentation"></span>
      <span className="confirm-checkbox__label" id="acknowledgement">
        { children }
      </span>
    </label>
  )
}

export default Checkbox;
import React, { Component } from 'react'
import ReactModal from '@galen/react-modal';

const ModalContext = React.createContext(0)

export function CloseButton({onClick}) {
  return (
    <button className="bg-transparent p-2 border-0 min-w-0" onClick={() => onClick()}>
      <span className="sr-only">Close Modal</span>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="presentation"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.742445 2.30104C0.332404 1.891 0.332403 1.22619 0.742444 0.816153V0.816153C1.15248 0.406114 1.81729 0.406114 2.22733 0.816153L14.1064 12.6952C14.5165 13.1052 14.5165 13.7701 14.1064 14.1801V14.1801C13.6964 14.5901 13.0316 14.5901 12.6215 14.1801L0.742445 2.30104Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6247 0.816662C13.0348 0.406622 13.6996 0.406621 14.1096 0.816661V0.816661C14.5197 1.2267 14.5197 1.8915 14.1096 2.30154L2.23055 14.1806C1.82051 14.5906 1.1557 14.5907 0.745661 14.1806V14.1806C0.335621 13.7706 0.335621 13.1058 0.745661 12.6957L12.6247 0.816662Z"
          fill="currentColor"
        />
      </svg>
    </button>
  )
}

export class ModalProvider extends Component {
  constructor(props) {
    super(props)

    this.state = {
      stack: [],
    }
  }

  setModal = (modal, modalProps = {}) => {
    this.setState(state => {
      const { stack } = state
      if (!modal) {
        const [_current, ...rest] = stack
        return {stack: rest, modalProps: {}}
      }
      return {stack: [modal, ...stack], modalProps}
    })
  }

  render() {
    const { stack, modalProps } = this.state;
    const isModalOpen = stack.length > 0
    const { showCloseButton, canClose } = this.props; 

    // const closeModal = () => this.setModal()
    const closeModal = () => {
      this.setModal()
    }

    // Function to safely invoke the first item in the stack if it's a function
    const renderModalContent = () => {
      if (typeof stack[0] === 'function') {
        return stack[0]();
      }
      
      // handle the case where stack[0] is not a function
      console.error('The first item in the stack is not a function');
      return null;
    };

    return (
      <>
        <ModalContext.Provider value={this.setModal}>
          { this.props.children }
        </ModalContext.Provider>

        <Modal 
          isOpen={isModalOpen} 
          onRequestClose={closeModal} 
          showCloseButton={showCloseButton}
          {...modalProps}
        >
          {isModalOpen && renderModalContent()}
        </Modal>

      </>
    )
  }
}

export function Modal({children, onRequestClose, canClose=true, showCloseButton=true, className = "",  ...rest}) {
    return (
        <ReactModal onRequestClose={onRequestClose} className={className} overlayClassName={`${className}-overlay`} {...rest}>
            {showCloseButton && (
                <div className='flex justify-end'>
                    <CloseButton onClick={onRequestClose}/>
                </div>
            )}
            {children}
        </ReactModal>
    )
}

export default ModalContext

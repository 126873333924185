import React, {useState, useEffect} from 'react'
import BaseScreen from '../BaseScreen/BaseScreen'
import { useParams } from 'react-router-dom'
import { client } from '@galen/api-client'

// global styles
import './KickoutScreen.css'

function KickoutScreen({id, title, onClick, onEvent, children, state, images }) {
  const { assessmentId } = useParams()
  const [assessmentNumber, setAssessmentNumber] = useState(-1)

  useEffect(() => {
    onEvent({componentId: id, type: 'SCREEN_DISPLAYED'})
  }, [id])

  useEffect(() => {
    client.getState({assessmentid: assessmentId})
      .then(response => {
        setAssessmentNumber(response.assessmentnumber)
      })
      .catch(e => console.error(e))
  }, [])

  return <BaseScreen
      id={`kickout ${id}`}
      title={title}
      onClick={onClick}
      showButton={false}
      showBackButton={false}
      contentType="info kickout"
      assessmentNumber={assessmentNumber}
      state={state}
      answers={state.answers}
      images={images}
    >
      {children}
    </BaseScreen>
}

export default KickoutScreen
